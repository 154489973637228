<template>
    <footer class="bg-footer">
        <div class="container">
            <h3>{{footerHeading}}</h3>
            <p>{{footerCopy}}</p>
            <a
                href="https://plansource.com/partners/marketplace/"
                class="btn-secondary button"
                target="_blank"
                rel="noopener nofollow"
            >{{buttonCopy}}</a>
        </div>
    </footer>
</template>

<script>

// Data
import results from '@/data/site-copy'

const { footerHeading, footerCopy, buttonCopy } = results.results

export default {
    name: 'results-footer',
    computed: {
        footerHeading() { return footerHeading },
        footerCopy() { return footerCopy },
        buttonCopy() { return buttonCopy }
    }
}

</script>

<style lang="scss" scoped>

@import '@/assets/scss/mixins.scss';
@import '@/assets/scss/functions.scss';

footer {
    height: auto;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    align-items: center;

    @include respond-to(medium-and-up) {
        height: 440px;
    }

    .container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-bottom: 60px;

        h3 {
            margin-bottom: 18px;
        }

        .button {
            height: 51px;

            @include respond-to(medium-and-up) {
                width: 323px!important;
            }
        }

        p {
            max-width: 741px;
            margin-bottom: 26px;
            text-align: center;
        }
    }
}

</style>