<template>
    <div>
        <ul class="partners" ref="partners">
            <li v-for="partner in recommendedPartners" :key="partner.partner">
                <Partner :partner="partner" />
            </li>
        </ul>
        <div class="swiper results-swiper" ref="resultsSwiper">
            <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="slide in recommendedPartners" :key="slide.partner">
                    <Partner :partner="slide" />
                </div>
            </div>
            <div class="swiper-scrollbar"></div>
        </div>
    </div>
</template>

<script>

// Modules.
import Swiper, { Mousewheel, Scrollbar } from 'swiper'
Swiper.use([Mousewheel, Scrollbar])

// Styles
import 'swiper/swiper.min.css'
import 'swiper/css/scrollbar'
import 'swiper/scss/mousewheel'
import 'swiper/scss/free-mode'

// Components
import Partner from '@/components/Partner.vue'

export default {
    name: 'recommended-partners',
    components: { Partner },
    props: {
        recommendedPartners: { type: Array, default() { return [] } },
        pdf: { type: Boolean, default: null }
    },
    data() {
        return {
            swiper: null
        }
    },
    watch: {
        pdf(newVal) {
            if (newVal) {
                this.$refs.partners.classList.add('active')
                this.$refs.resultsSwiper.classList.add('inactive')
            } else {
                this.$refs.partners.classList.remove('active')
                this.$refs.resultsSwiper.classList.remove('inactive')
            }
        }
    },
    mounted() {
        this.swiper = new Swiper(this.$refs.resultsSwiper, {
            slideToClickedSlide: true,
            slidesPerView: 0.9,
            threshold: 10,
            loop: false,
            freeMode: {
                enabled: true,
                sticky: false,
                momentumBounce: false,
            },
            grabCursor: true,
            scrollbar: {
                el: '.swiper-scrollbar',
                draggable: true,
                dragSize: 100,
                forceToAxis: true
            },
            mousewheel: {
                enabled: true,
                forceToAxis: true,
            },
            breakpoints: {
                750: {
                    slidesPerView: 1.9,
                },
                700: {
                    slidesPerView: 1.7,
                },
                650: {
                    slidesPerView: 1.6,
                },
                600: {
                    slidesPerView: 1.5,
                },
                550: {
                    slidesPerView: 1.4,
                },
                500: {
                    slidesPerView: 1.2,
                },
                450: {
                    slidesPerView: 1.1,
                },   
                400: {
                    slidesPerView: 0.95,
                }            
            }
        })
    }
}

</script>

<style lang="scss" scoped>

@import '@/assets/scss/mixins.scss';
@import '@/assets/scss/functions.scss';

.partners {
    display: none;
    flex-wrap: wrap;
    padding: 0;

    li:nth-child(odd) {
        margin-right: 50px;
    }

    @include respond-to(medium-and-up) {
        display: flex
    }

    &.active {
        display: flex;
    }
}

.results-swiper {
    display: block;
    margin-bottom: 70px;

    @include respond-to(medium-and-up) {
        display: none;
    }

    &.inactive {
        display: none;
    }

    .swiper-wrapper {
        margin-bottom: 38px;
    }

    .swiper-slide {
        width: auto!important;
        margin-right: 30px!important;
    }

    .swiper-scrollbar {
        background: var(--peq-white);
        border-radius: 0;

        .swiper-scrollbar-drag {
            background: var(--peq-navy);
            border-radius: 0;
        }
    }
}

</style>