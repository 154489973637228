<template>
    <div class="partner">
        <div class="logo-container">
            <img class="logo" :src="getImagePath(partner.logo, 'partner-logos')" alt="Partner logo" />
        </div>
        <p class="partner-copy">{{partner.copy}}</p>
        <a
            :href="partner.link"
            class="btn-primary button"
            title="Explore partner"
            target="_blank"
            rel="noopener nofollow"
        >Inquire</a>
    </div>
</template>

<script>

// Helpers
import helpers from '@/helpers/helpers'

export default {
    // eslint-disable-next-line
    name: 'partner',
    props: {
        partner: { type: Object, default() { return {} } }
    },
    data() {
        return {
            getImagePath: helpers.getImagePath
        }
    }
}

</script>

<style lang="scss" scoped>

@import '@/assets/scss/mixins.scss';
@import '@/assets/scss/functions.scss';

.partner {
    background: var(--peq-white);
    border: 1px solid var(--peq-light-grey);
    border-radius: 10px;
    margin-bottom: 18px;
    padding: 30px 34px;
    max-width: 275px;
    width: 430px;
    height: 400px;
    display: flex;
    flex-direction: column;

    @include respond-to(medium-and-up) {
        max-width: 516px;
        height: 425px;
    }

    button {
        margin-top: auto;
    }

    .logo-container {
        background: rgba(0, 0, 0, 0.06);
        border-radius: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 16px;
        width: 179px;
        height: 118px;

        img {
            width: 150px;
            height: auto;

            @include respond-to(medium-and-up) {
                width: 200px;
                height: auto;
            }
        }

        @include respond-to(medium-and-up) {
            width: 429px;
            height: 118px;
        }
    }

    p {
        max-width: 456px;
        min-height: 200px;
        margin-bottom: 19px;
    }
}

</style>