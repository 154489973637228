<template>
    <ul :class="`question-cards ${type}`">
        <li v-for="question in questions" :key="question.copy" translate="no">
            <QuestionCard
                :icon="question.icon"
                :copy="question.copy"
                :opensModal="question.opensModal"
                :toggleActiveCard="toggleActiveCard"
                :type="type"
                :updateSelection="updateSelection"
                :selection="selection !== '' ? selection : selections[selections.indexOf(question.copy)]"
            />
        </li>
    </ul>
</template>

<script>

// Components
import QuestionCard from '@/components/QuestionCard.vue'

export default {
    name: 'question-cards',
    components: { QuestionCard },
    props: {
        questions: { type: Array, default() { return [] }},
        type: { type: String, default: 'primary' },
        updateSelection: { type: Function, default: () => {} },
        selection: { type: String, default: '' },
        selections: { type: Array, default() { return [] } },
        multiSelect: { type: Boolean, default: false }
    },
    methods: {
        toggleActiveCard(selection) {
            const card = selection.classList.contains('question-card') ? selection : selection.parentElement
            if (!this.multiSelect) {
                document.querySelectorAll('.question-card').forEach(element => {
                    element.classList.remove('active')
                })
                card.classList.add('active')
            } else {
                card.classList.toggle('active')
            }

            this.questions.forEach((question) => {
                if (card.getElementsByTagName('p')[0].innerHTML === question.copy) {
                    if (question.opensModal) {
                        this.$store.commit('toggleModal', {
                            modalOpen: !this.modalOpen
                        })
                    }
                }
            })
        }
    }
}

</script>

<style lang="scss" scoped>

@import '@/assets/scss/mixins.scss';
@import '@/assets/scss/functions.scss';

.question-cards {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0;

    @include respond-to(medium-and-up) {
        margin-bottom: 30px;
    }

    li {
        display: flex;
        justify-content: center;
    }
}

</style>