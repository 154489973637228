<template>
    <button 
        :class="`${btnClass} ${disabled ? 'btn-disabled' : ''}`"
        v-if="btnRoute && btnText"
        ref="button"
        @click="routeToPage($event); closeModal();"
    >
        {{btnText}}
    </button>
</template>

<script>

// Helpers
import helpers from '@/helpers/helpers'

// Animations
import { fadeOut } from '@/helpers/animations'

export default {
    // eslint-disable-next-line
    name: 'Button',
    props: {
        btnClass: { type: String, default: '' },
        btnText: { type: String, default: '' },
        btnRoute: { type: [Number, String], default: null },
        formData: { type: Object, default: null },
        formKey: { type: String, default: '' },
        submitForm: { type: Boolean, default: false },
        localQueryParams: { type: Object, default() { return {} } },
        disabled: { type: Boolean, default: true },
        modalToggle: { type: Boolean, default: false },
        skipQuestion: { type: Boolean, default: false },
        animationItems: { type: Array, default() { return [] } }
    },
    methods: {
        routeToPage(e) {
            if (this.disabled) {
                e.preventDefault()
                return
            }

            if (this.formData) {

                this.commitGlobalParams()

                if (
                    (typeof this.btnRoute === 'string' || this.btnRoute instanceof String) && 
                    helpers.formValidation(this.formKey, this.formData).valid && 
                    Object.keys(this.formData).length !== 0
                ) {
                    
                    this.commitErrorFields({})
                    this.commitForm()
                    this.updateAnalytics()
                    this.submitTheForm()
                    this.triggerAnimations()
                    
                    setTimeout(() => {
                        this.$router.push({ path: this.btnRoute, query: this.$store.state.globalQueryParams})
                    }, 700)
                }

                if (!helpers.formValidation(this.formKey, this.formData).valid) {
                    this.commitErrorFields(helpers.formValidation(this.formKey, this.formData).errorFields)
                }
            }

            if (typeof this.btnRoute === 'number') {
                this.$router.go(this.btnRoute)
            } else if (this.btnRoute === '/personalize' || this.skipQuestion) {
                this.$router.push({ path: this.btnRoute, query: this.$store.state.globalQueryParams})
                this.submitTheForm()
                this.updateAnalytics()
            }
        },
        commitGlobalParams() {
            if (Object.keys(this.localQueryParams).length !== 0) {
                this.$store.commit('updateGlobalQueryParams', {
                    queryParams: this.localQueryParams
                })
            }
        },
        commitErrorFields(fields) {
            this.$store.commit('updateErrorFields', {
                fields: fields
            })
        },
        commitForm() {

            this.$store.commit('updateGlobalForm', {
                key: this.formKey, 
                data: this.formData
            })
        },
        updateAnalytics() {
            if (
                this.formKey &&
                !this.$store.state.globalAnalytics[this.formKey] &&
                process.env.NODE_ENV !== 'development'
            ) {
                this.$store.commit('updateglobalAnalytics', {
                    key: this.formKey, 
                    data: true
                })
                this.$gtag.event('question', { label: this.formKey })

                if ( this.formKey === 'personalizeForm' ) {
                    window.lintrk('track', { conversion_id: 12383233 })
                }
            }
        },
        submitTheForm() {

            // Remove the NODE_ENV check if you want to test submitting form from dev
            if (this.submitForm && process.env.NODE_ENV !== 'development') {
                document.getElementById("global-form").submit()
                console.log('Form Submitted')
            }
        },
        triggerAnimations() {
            if (this.animationItems) {
                this.animationItems.forEach(item => {
                    fadeOut(item, 0.1, 0.5)
                })
                fadeOut('#button', 0.1, 0.5)
            }
        },
        closeModal() {
            if (this.modalToggle) {
                this.$store.commit('toggleModal', {
                    modalOpen: false
                })   
            }
        }
    }
}
</script>
