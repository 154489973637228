<template>
    <ul class="filtering-container" translate="no">
        <li 
            :class="`${activeItem === category ? 'active' : ''}`" 
            v-for="category in categories" 
            :key="category"
            @click="updateActiveItem($event.target.innerHTML); updateFilterBy($event.target.innerHTML);"
        >
            <p>{{category}}</p>
        </li>
    </ul>
</template>

<script>

export default {
    name: 'filtering-navigation-bar',
    props: { 
        categories: { type: Array, default() { return [] }},
        updateFilterBy: { type: Function, default: () => {} }
    },
    data() {
        return {
            activeItem: 'All'
        }
    },
    methods: {
        updateActiveItem(item) {
            this.activeItem = item
        }
    }
}

</script>

<style lang="scss" scoped>

@import '@/assets/scss/mixins.scss';
@import '@/assets/scss/functions.scss';

.filtering-container {
    padding: 0;
    display: flex;
    text-align: center;
    overflow: hidden;
    overflow-x: scroll;
    max-width: 300px;
    white-space: nowrap;
    position: relative;

    &::-webkit-scrollbar {
        display: none;
    }

    @include respond-to(medium-and-up) {
        max-width: unset;
    }

    li {
        margin-right: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        min-width: fit-content;

        p {
            margin-bottom: 0;
        }

        &:hover {
            cursor: pointer;
        }

        &.active {

            &:after {
                content: "";
                display: block;
                width: 100%;
                height: 2px;
                background-color: var(--peq-orange);
            }
        }
    }
}

.filtering-swiper {

    .swiper-slide {
        width: 600px;
        display: flex;
        justify-content: center;
    }
}

</style>