<template>
    <div id="app">
        <NavigationBar />
        <router-view />
        <GlobalForm />
    </div>
</template>

<script>

// Components
import NavigationBar from './components/NavigationBar.vue'
import GlobalForm from '@/components/GlobalForm.vue'

export default {
    name: 'App',
    components: {
        NavigationBar,
        GlobalForm
    },
    created() {
        const root = document.getElementsByTagName('html')[0]
        document.body.classList.add('height-full')
        root.setAttribute('class', 'height-full')

        if (this.$router.history.current.name !== 'results' && this.$router.history.current.fullPath !== '/') {
            this.$router.push({ path: '/'})
        }
    },
}
</script>

<style lang="scss" scoped>

#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

</style>
