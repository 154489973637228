<template>
    <section class="bg-tertiary baseline margin-auto question-2">
        <div class="container flex-group-column" ref="container" id="container">
            <p class="question-number">Question 2 of 6</p>
            <h3 ref="heading">{{heading}}</h3>
            <QuestionCards
                :questions="questions"
                type="primary"
                :updateSelection="updateSelection"
                :selection="question2Form.selection"
            />
        </div>
        <div class="flex-group button-group" ref="buttons" id="button">
            <Button
                btn-class="btn-secondary"
                btn-text="Back"
                :btn-route="-1"
                :disabled="false"
            />
            <Button
                btn-class="btn-primary"
                btn-route="/question-3"
                btn-text="Continue"
                :local-query-params="constructedQueryParams"
                :disabled="!formHasData"
                :form-data="formData"
                form-key="question2Form"
                :animation-items="['#container']"
            />
        </div>
        <Modal>
            <h5>{{modalHeading}}</h5>
            <p>{{modalSubHeading}}</p>
            <Slider
                :slider-points="sliderPoints"
                :selection="question2Form.selectionEstimate"
                :updateSelection="updateSelectionEstimate"
                :snap-to="false"
                :minusValue="2"
                :minus-value-handle="minusValueHandle"
                :init-on-mount="false"
                :isModal="true"
            />
            <Button
                btn-class="btn-primary"
                btn-route="/question-3"
                btn-text="Continue"
                :local-query-params="constructedQueryParams"
                :disabled="disabled"
                :form-data="formData"
                form-key="question2Form"
                :modal-toggle="true"
                :animation-items="['#container']"
            />
        </Modal>
    </section>
</template>

<script>

// Helpers
import helpers from '@/helpers/helpers'

// Components
import Button from '@/components/Button.vue'
import QuestionCards from '@/components/QuestionCards.vue'
import Modal from '@/components/Modal.vue'
import Slider from '@/components/Slider.vue'

// Animations
import { fadeInUp } from '@/helpers/animations'

// Data
import question2 from '@/data/site-copy'

const {
    heading,
    sliderPoints,
    modalHeading,
    modalSubHeading,
    questions,
    tooltip,
} = question2.question2

export default {
    name: 'question-2',
    components: {
        Button,
        QuestionCards,
        Modal,
        Slider
    },
    data() {
        return {
            question2Form: {
                selection: 
                    typeof(this.$store.state.globalFormData.question2Form.selection) !== 'undefined' ?
                    this.$store.state.globalFormData.question2Form.selection :
                    '',
                selectionEstimate: 
                    typeof(this.$store.state.globalFormData.question2Form.selectionEstimate) !== 'undefined' ?
                    this.$store.state.globalFormData.question2Form.selectionEstimate :
                    ''
            },
            constructedQueryParams: null
        }
    },
    computed: {
        formHasData() {
            return this.question2Form.selection !== '' ||
            this.question2Form.selectionEstimate !== ''
        },
        formData() { return this.formHasData ? this.question2Form : {} },
        disabled() { return !this.formHasData },
        heading() { return heading },
        sliderPoints() { return sliderPoints },
        modalHeading() { return modalHeading },
        modalSubHeading() { return modalSubHeading },
        questions() {return questions },
        tooltip() {return tooltip },
        minusValueHandle() {
            return window.innerWidth < 767 ? 5 : 2
        }
    },
    methods: {
        updateSelection(selection, opensModal) {
            const card = selection.classList.contains('question-card') ? selection : selection.parentElement
            this.question2Form.selection = card.getElementsByTagName('p')[0].innerHTML
            this.question2Form.selectionEstimate = ''
            if (opensModal) {
                this.question2Form.selectionEstimate = sliderPoints[0].replace('$','')
            }
        },
        updateSelectionEstimate(selection) {
            this.question2Form.selectionEstimate = selection.replace('$','')
        },
        constructQueryParams() {
            const params = {
                estimatedBudget: this.question2Form.selectionEstimate
            }
            this.constructedQueryParams = params
        }
    },
    watch: {
        'question2Form.selectionEstimate': function () {
            this.constructQueryParams()
        }
    },
    created() {
        helpers.adjustHeight(true)
    },
    mounted() {
        this.constructQueryParams()
        fadeInUp(this.$refs.container, 0.1, 0.7)
        fadeInUp(this.$refs.buttons, 0.1, 0.7)
    }
}
</script>

<style lang="scss" scoped>

@import '@/assets/scss/mixins.scss';
@import '@/assets/scss/functions.scss';

section {

    h3 {
        margin-bottom: 30px;
    }
}

</style>