<template>
    <section class="bg-tertiary baseline margin-auto question-7">
        <div class="container flex-group-column" ref="container" id="container">
            <p class="question-number">Question 6 of 6</p>
            <h3>{{heading}}</h3>
            <QuestionCards
                :questions="questions"
                type="primary"
                :updateSelection="updateSelection"
                :selection="question6Form.selection"
            />
        </div>
        <div class="flex-group button-group" ref="buttons" id="button">
            <Button 
                btn-class="btn-secondary"
                btn-text="Back"
                :btn-route="-1"
                :disabled="false"
            />
            <Button
                btn-class="btn-primary"
                btn-route="/loading-screen-2"
                btn-text="Continue"
                :disabled="!formHasData"
                :form-data="formData"
                form-key="question6Form"
                :animation-items="['#container']"
            />
        </div>
    </section>
</template>

<script>

// Helpers
import helpers from '@/helpers/helpers'

// Components
import Button from '@/components/Button.vue'
import QuestionCards from '@/components/QuestionCards.vue'

// Animations
import { fadeInUp } from '@/helpers/animations'

// Data
import question6 from '@/data/site-copy'
const { heading, questions } = question6.question6

export default {
    name: 'question-6',
    components: { Button, QuestionCards },
    data() {
        return {
            question6Form: {
                selection: 
                    typeof(this.$store.state.globalFormData.question6Form.selection) !== 'undefined' ?
                    this.$store.state.globalFormData.question6Form.selection :
                    ''
            }
        }
    },
    computed: {
        formHasData() { return this.question6Form.selection !== '' },
        formData() { return this.formHasData ? this.question6Form : {} },
        heading() { return heading },
        questions() { return questions },
    },
    methods: {
        updateSelection(selection) {
            const card = selection.classList.contains('question-card') ? selection : selection.parentElement
            const text = card.getElementsByTagName('p')[0].innerHTML
            this.question6Form.selection = text
        }
    },
    created() {
        helpers.adjustHeight(true)
    },
    mounted() {
        fadeInUp(this.$refs.container, 0.1, 0.7)
        fadeInUp(this.$refs.buttons, 0.1, 0.7)
    }
}
</script>

<style lang="scss" scoped>

section {

    h3 {
        margin-bottom: 30px;
    }
}

</style>