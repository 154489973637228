<template>
    <section class="bg-tertiary">
        <div class="container">
            <h1>404</h1>
            <h3>Oops.. that page was not found.</h3>
            <a href="/">Try the benefit assessment tool</a>
        </div>
    </section>
</template>

<script>

// Helpers
import helpers from '@/helpers/helpers'

export default {
    name: 'not-found',
    created() {

        helpers.adjustHeight(true)
    },
}

</script>

<style lang="scss" scoped>

@import '@/assets/scss/mixins.scss';
@import '@/assets/scss/functions.scss';

section {

    .container {
        text-align: center;
    }

    h1 {
        font-weight: 400;
        font-size: rem(280);
        line-height: 100%;
        text-align: center;
        letter-spacing: -0.01em;
        color: var(--peq-off-black);
        margin-top: 0;
        margin-bottom: 22px;
    }

    h3 {
        margin-top: 0;
        margin-bottom: 22px;
        font-weight: 400;
        font-size: rem(32);
        line-height: 130%;
        text-align: center;
        letter-spacing: -0.01em;
        color: var(peq--black);
    }

    a {
        font-weight: 400;
        font-size: rem(16);
        line-height: 150%;
        text-align: center;
        letter-spacing: 0.01em;
        text-decoration-line: underline;
        color: var(--peq-black);
    }
}

</style>