import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vuex from 'vuex'
import VueGtag from 'vue-gtag'
import Donut from 'vue-css-donut-chart'

// Styles
import './assets/scss/main.scss'
import 'vue-css-donut-chart/dist/vcdonut.css'

Vue.use(Vuex)
Vue.use(Donut)
Vue.config.productionTip = false

Vue.use(VueGtag, {config: { id: "G-X4DYHXVBQR" }})

const store = new Vuex.Store({
  state: {
    globalFormData: {
      personalizeForm: {},
      question1Form: {},
      question2Form: {},
      question3Form: {},
      question4Form: {},
      question5Form: {},
      question6Form: {},
    },
    globalAnalytics: {
      personalizeForm: false,
      question1Form: false,
      question2Form: false,
      question3Form: false,
      question4Form: false,
      question5Form: false,
      question6Form: false,
    },
    globalQueryParams: {},
    globalUrl: '',
    errorFields: {},
    modalOpen: false
  },
  mutations: {
    updateGlobalForm(state, payload) {
      state.globalFormData[payload.key] = payload.data
    },
    updateGlobalQueryParams(state, payload) {
      state.globalQueryParams = { ...state.globalQueryParams, ...payload.queryParams }
    },
    updateErrorFields(state, payload) {
      state.errorFields = payload.fields
    },
    updateGlobalUrl(state, payload) {
      state.globalUrl = payload.url
    },
    updateglobalAnalytics(state, payload) {
      state.globalAnalytics[payload.key] = payload.data
    },
    toggleModal(state, payload) {
      state.modalOpen = payload.modalOpen
      document.body.classList.toggle('body-noscroll')
    }
  }
})

new Vue({
  router,
  render: h => h(App),
  store: store
}).$mount('#app')
