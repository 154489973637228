<template>
    <section class="bg-tertiary baseline margin-auto question-3">
        <div class="container flex-group-column" ref="container" id="container">
            <p class="question-number">Question 3 of 6</p>
            <h3>{{heading}}</h3>
            <ul class="questions-container">
                <li v-for="question in questions" :key="question.formItem" class="question">
                    <div class="generation">
                        <h5>{{question.generation}}</h5>
                        <div class="year">
                            {{question.year}}
                        </div>
                    </div>
                    <Slider
                        :slider-points="question.sliderPoints"
                        :selection="question3Form[question.formItem]"
                        :update-selection="updateSelection"
                        :form-item="question.formItem"
                        :minus-value="1"
                        :minus-value-handle="2"
                    />
                </li>
            </ul>
        </div>
        <div class="flex-group button-group" ref="buttons" id="button">
            <Button
                btn-class="btn-secondary"
                btn-text="Back"
                :btn-route="-1"
                :disabled="false"
            />
            <Button 
                btn-class="btn-primary" 
                btn-route="/question-4"
                btn-text="Continue"
                :disabled="false"
                :form-data="question3Form"
                form-key="question3Form"
                :local-query-params="constructedQueryParams"
                :animation-items="['#container']"
            />
        </div>
    </section>
</template>

<script>

// Helpers
import helpers from '@/helpers/helpers'

// Data
import question3 from '@/data/site-copy'

// Components
import Button from '@/components/Button.vue'
import Slider from '@/components/Slider.vue'

// Animations
import { fadeInUp } from '@/helpers/animations'

const { heading, questions } = question3.question3

export default {
    name: 'question-3',
    components: { Button, Slider },
    data() {
        return {
            question3Form: {
                genZDemo: 
                    typeof(this.$store.state.globalFormData.question3Form.genZDemo) !== 'undefined' ?
                    this.$store.state.globalFormData.question3Form.genZDemo :
                    questions[0].sliderPoints[0],
                millennialDemo: 
                    typeof(this.$store.state.globalFormData.question3Form.millennialDemo) !== 'undefined' ?
                    this.$store.state.globalFormData.question3Form.millennialDemo :
                    questions[1].sliderPoints[0],
                genXDemo: 
                    typeof(this.$store.state.globalFormData.question3Form.genXDemo) !== 'undefined' ?
                    this.$store.state.globalFormData.question3Form.genXDemo :
                    questions[2].sliderPoints[0],
                boomerDemo: 
                    typeof(this.$store.state.globalFormData.question3Form.boomerDemo) !== 'undefined' ?
                    this.$store.state.globalFormData.question3Form.boomerDemo :
                    questions[3].sliderPoints[0]
            },
            constructedQueryParams: {}
        }
    },
    computed: {
        heading() { return heading },
        questions() { return questions },
    },
    methods: {
        updateSelection(selection, formItem) {
            this.question3Form[formItem] = selection
        },
        constructQueryParams() {
            const params = {
                genZDemo: this.question3Form.genZDemo,
                millennialDemo: this.question3Form.millennialDemo,
                genXDemo: this.question3Form.genXDemo,
                boomerDemo: this.question3Form.boomerDemo
            }
            this.constructedQueryParams = params
        }
    },
    watch: {
        'question3Form.genZDemo': function () {
            this.constructQueryParams()
        },
        'question3Form.millennialDemo': function () {
            this.constructQueryParams()
        },
        'question3Form.genXDemo': function () {
            this.constructQueryParams()
        },
        'question3Form.boomerDemo': function () {
            this.constructQueryParams()
        },
    },
    created() {
        helpers.adjustHeight(true)
    },
    mounted() {
        this.constructQueryParams()
        fadeInUp(this.$refs.container, 0.1, 0.7)
        fadeInUp(this.$refs.buttons, 0.1, 0.7)
    },
}
</script>

<style lang="scss" scoped>

@import '@/assets/scss/mixins.scss';
@import '@/assets/scss/functions.scss';

section {

    h3 {
        margin-bottom: 30px;
    }

    .questions-container {
        padding: 0;
        width: 90%;
        margin: 0;

        @include respond-to(medium-and-up) {
            width: 100%;
        }

        @include respond-to(medium-and-down) {
            margin-bottom: 30px;
        }

        .question {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 30px;

            @include respond-to(medium-and-up) {
                flex-direction: row;
            }

            &:not(:last-child) {
                border-bottom: 1px solid var(--peq-light-grey);
                padding-bottom: 30px;

                @include respond-to(medium-and-up) {
                    padding-bottom: 7px;
                }
            }

            .generation {
                width: 100%;
                margin-bottom: 30px;

                @include respond-to(medium-and-up) {
                    width: 20%;
                    margin-bottom: 17px;
                }

                h5 {
                    margin-bottom: 9px;
                    position: relative;
                    width: fit-content;
                    display: flex;
                    flex-direction: column-reverse;
                }

                .year {
                    font-size: rem(12);
                    background: #E6E6E6;
                    border-radius: 50px;
                    width: fit-content;
                    padding: 2px 16px 2px 12px;
                }
            }

            .slider-container {
                width: 90%;
                margin: 0;
                margin-right: auto;
                height: 30px;
                margin-left: 5px;

                @include respond-to(medium-and-up) {
                    width: 75%;
                    margin-left: auto;
                }
            }
        }
    }
}

</style>