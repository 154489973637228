<template>
    <section class="bg-tertiary margin-auto">
        <div class="container flex-group-column" ref="container">
            <img 
                class="loading-screen-img"
                src="@/assets/images/loading-screen-1-img.png"
                alt="Loading screen image"
                ref="loadingScreenImg"
            />
            <h3 class="heading" ref="loadingScreenHeading">Nice to meet you, {{firstName}}!</h3>
            <p class="subheading" ref="loadingScreenSubHeading">{{subheading}}</p>
        </div>
    </section>
</template>

<script>

// Helpers
import helpers from '@/helpers/helpers'

// Animations
import { fadeIn, fadeOut } from '@/helpers/animations'

// Data
import loadingScreen1 from '@/data/site-copy'
const { subheading } = loadingScreen1.loadingScreen1

export default {
    name: 'loading-screen-1',
    computed: {
        firstName() { return this.$store.state.globalFormData.personalizeForm.firstName },
        subheading() { return subheading }
    },
    created() {

        helpers.adjustHeight(true)
    },
    mounted() {
        fadeIn(this.$refs.container, 0, 0.7)

        setTimeout(() => {
            fadeOut(this.$refs.container, 0, 0.5)
        }, 5000)

        setTimeout(() => {
            this.$router.push({ path: '/question-1', query: this.$store.state.globalQueryParams})
        }, 6000)
    }
}
</script>

<style lang="scss" scoped>

@import '@/assets/scss/mixins.scss';
@import '@/assets/scss/functions.scss';

section {

    .heading {
        margin-bottom: 16px;
    }

    .subheading {
        width: 345px;
        text-align: center;
    }

    .loading-screen-img {
        width: 207px;
        height: 207px;
        
        @include respond-to(medium-and-up) {
            width: 376px;
            height: 376px;
        }
    }
}

</style>