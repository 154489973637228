<template>
    <div 
        :class="`question-card ${type} ${copy === selection ? 'active' : ''}`" 
        @click="toggleActiveCard($event.target); 
        updateSelection($event.target, opensModal);"
    >
        <img class="logo" :src="getImagePath(icon, 'icons')" alt="Question icon" />
        <p>{{copy}}</p>
        <div class="select-icon"></div>
    </div>
</template>

<script>

// Helpers
import helpers from '@/helpers/helpers'

export default {
    name: 'question-card',
    props: {
        icon: { type: String, default: '' },
        copy: { type: String, default: '' },
        opensModal: { type: Boolean, default: false },
        toggleActiveCard: { type: Function, default: () => {} },
        type: { type: String, default: 'primary' },
        updateSelection: { type: Function, default: () => {} },
        selection: { type: String, default: '' }
    },
    data() {
        return {
            getImagePath: helpers.getImagePath
        }
    }
}

</script>

<style lang="scss" scoped>

@import '@/assets/scss/mixins.scss';
@import '@/assets/scss/functions.scss';

.question-card {
    background: var(--peq-white);
    border: 1px solid var(--peq-light-grey);
    margin-right: 6px;
    display: flex;
    align-items: center;
    margin-bottom: 27px;

    @include respond-to(wide-and-up) {
        margin-right: 24px;
    }

    &.primary {
        width: 159px;
        height: 159px;
        border-radius: 15px;
        flex-direction: column;
        justify-content: center;
        margin-right: 12px;
        margin-left: 12px;

        @include respond-to(wide-and-up) {
            width: 245px;
            height: 256px;
        }

        p {
            text-align: center;
            max-width: 90%;
            margin-bottom: 12px;
            font-size: rem(12);
            line-height: rem(12);
            min-height: 35px;
            @include line-clamp(3);

            @include respond-to(wide-and-up) {
                margin-bottom: 27px;
                font-size: rem(15);
                line-height: rem(15);
            }
        }

        img {
            width: 30px;
            height: 30px;

            @include respond-to(wide-and-up) {
                width: 60px;
                height: 60px;
            }
        }
    }

    &.secondary {
        width: 352px;
        max-width: 90%;
        height: 51px;
        border-radius: 200px;
        flex-direction: row-reverse;
        justify-content: flex-end;

        p {
            margin-bottom: 0;
        }

        img {
            display: none;
        }

        .select-icon {
            margin-left: 15px;
            margin-right: 17px;
        }
    }

    img {
        margin-bottom: 18px;
        @include respond-to(wide-and-up) {
            margin-bottom: 32px;
        }
    }

    &.active {
        background: var(--peq-navy);
        color: var(--peq-white);
        
        p {
            color: var(--peq-white);
        }

        img {
            filter: invert(98%) sepia(98%) saturate(0%) hue-rotate(5deg) brightness(103%) contrast(102%);
        }

        .select-icon {
            background-color: var(--peq-green);
            border: none;

            &:before,
            &:after {
                display: block;
            }
        }
    }

    &:hover {
        cursor: pointer;
    }

    p {
        font-weight: 600;
        margin-bottom: 24px;
    }

    .select-icon {
        width: 24px;
        height: 24px;
        border: 1px solid var(--peq-black-600);
        border-radius: 50%;
        position: relative;
        display: inline-block;

        @include respond-to(wide-and-up) {
            width: 31px;
            height: 31px;
        }

        &:before {
            position: absolute;
            left: 0;
            top: 42%;
            height: 25%;
            width: 2px;
            background-color: var(--peq-white);
            content: "";
            transform: translateX(10px) rotate(-45deg);
            transform-origin: left bottom;

            @include respond-to(wide-and-up) {
                left: 3px;
            }
        }

        &:after {
            position: absolute;
            left: 0;
            bottom: 8px;
            height: 2px;
            width: 48%;
            background-color: var(--peq-white);
            content: "";
            transform: translateX(10px) rotate(-45deg);
            transform-origin: left bottom;

            @include respond-to(wide-and-up) {
                left: 4px;
                bottom: 10px;
            }
        }
    }
}

</style>