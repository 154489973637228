export default {
  partners: [
    {
      partner: "Accolade",
      copy: "Accolade is a clinician-led, personalized healthcare company. We are the one point of contact for your people for everything health and benefits related.",
      logo: "accolade-logo",
      benefits: ["Navigation/Advocacy", "Telemedicine/Virtual Primary Care"],
      link: "https://plansource.com/partners/marketplace/health/accolade/",
    },
    {
      partner: "Allstate Identity Protection",
      copy: "Allstate Identity Protection, formerly known as InfoArmor, has been a leader in the employee benefits space for over 13 years. With the launch of their new privacy tool, Allstate Digital Footprint, they are empowering more people to live their best lives online.",
      logo: "allstate-logo",
      benefits: ["Identity Theft"],
      link: "https://plansource.com/partners/marketplace/wealth/allstate-ip/",
    },
    {
      partner: "AllyHealth",
      copy: "AllyHealth helps your employees be more proactive about their health and wellness by giving them access to the right professionals on their schedule and on their terms. And our modular platform allows you to pick and choose which virtual care solutions are best for your employees, providing you with the most flexible platform in the industry.",
      logo: "ally-health-logo",
      benefits: [
        "Employee Assistance Programs (EAP)",
        "Mental/Behavioral Health",
        "Telemedicine/Virtual Primary Care",
        "Whole Health",
      ],
      link: "https://plansource.com/health/allyhealth/",
    },
    {
      partner: "ARAG Legal",
      copy: "As the world's leading legal provider, ARAG connects people with professional legal help saving them time, stress and money. From the good times such as buying a house or adopting a child, to the struggles like getting a divorce or having your identity stolen, ARAG protects 26.3 million individuals and their families worldwide.",
      logo: "arag-logo",
      benefits: ["Legal"],
      link: "https://plansource.com/partners/marketplace/wealth/arag-legal/",
    },
    {
      partner: "ARC Fertility",
      copy: "ARC Fertility delivers inclusive, comprehensive family-forming benefits through an individualized digital platform, personal navigation, emotional support, top-tier fertility clinics and innovative financial solutions.",
      logo: "arc-fertility-logo",
      benefits: ["Fertility Treatment", "Women's Health", "Family Health"],
      link: "https://plansource.com/health/arc-fertility/",
    },
    {
      partner: "Carrot Fertility",
      copy: "Carrot provides inclusive, comprehensive fertility healthcare and family-forming benefits.From supporting fertility health, assisted reproduction like in vitro fertilization, preservation like egg or sperm freezing, adoption, gestational surrogacy, pregnancy, menopause, and more, Carrot is designed to make care accessible and affordable for all.",
      logo: "carrot-logo",
      benefits: ["Women's Health", "Fertility Treatment"],
      link: "https://plansource.com/marketplace/health/carrot-fertility",
    },
    {
      partner: "Cleo",
      copy: "Cleo supports parents and caregivers so working families can thrive.",
      logo: "cleo-logo",
      benefits: ["Caregiver Support", "Women's Health", "Family Health"],
      link: "https://plansource.com/health/cleo/",
    },
    {
      partner: "CirrusMD",
      copy: "CirrusMD provides employees with the fastest, most effortless access to virtual primary care doctors; they begin consulting with a physician on demand in less than 60 seconds.",
      logo: "cirrus-md-logo",
      benefits: [
        "Mental/Behavioral Health",
        "Telemedicine/Virtual Primary Care",
        "Whole Health",
      ],
      link: "https://plansource.com/partners/marketplace/health/cirrusmd/",
    },
    {
      partner: "Dario Health",
      copy: "Dario is a leading digital health company revolutionizing how people with chronic conditions manage their health. Our solutions deliver personalized and dynamic interventions driven by data analytics and one-on-one coaching to address diabetes, pre-diabetes, hypertension, musculoskeletal and behavioral health needs.",
      logo: "dario-health-logo",
      benefits: [
        "Chronic Disease Management",
        "Obesity Management",
        "Musculoskeletal",
      ],
      link: "https://plansource.com/health/dariohealth/",
    },
    {
      partner: "Empathy",
      copy: "With comprehensive support for navigating grief and settling the estate, Empathy is designed to help bereaved families find balance during one of life's most challenging moments.",
      logo: "empathy-logo",
      benefits: [
        "Family Health",
        "Employee Assistance Programs (EAP)",
        "Caregiver Support",
      ],
      link: "https://plansource.com/health/empathy/",
    },
    {
      partner: "Employer DDS (by Empower DDS)",
      copy: "With Employer DDS, dentists can offer the services their patients need on a monthly or annual basis. Patients get access to a preventative plan that allows them to secure their healthcare needs for a predictable monthly or yearly amount. Everyone wins!",
      logo: "employer-dds-logo",
      benefits: ["Dental Care"],
      link: "https://plansource.com/partners/marketplace/health/employerdds/",
    },
    {
      partner: "EarnIn",
      copy: "EarnIn is an app that gives you access to the pay you've earned - when you want it. Get paid for the hours you've worked without waiting for payday.",
      logo: "earnin-logo",
      benefits: [
        "Employee Rewards Programs",
        "Financial Education",
        "Financial Wellness",
      ],
      link: "https://plansource.com/wealth/earnin/",
    },
    {
      partner: "Experian",
      copy: "Experian is a modern personalized financial management solution that empowers employees to manage their data with confidence so they can maximize every opportunity.",
      logo: "experian-logo",
      benefits: ["Financial Wellness", "Identity Theft"],
      link: "https://plansource.com/partners/marketplace/wealth/experian/",
    },
    {
      partner: "Famlee",
      copy: "Famlee offers women seeking to start a family a unique, convenient, at-home fertility evaluation and treatment solution that is accessible. Famlee supports employees in the comfort of their home by focusing on improving ovulatory function through an at-home kit, Telehealth consultations with fertility-trained clinicians and home delivery of prescriptions and supplements.",
      logo: "famlee-logo",
      benefits: [
        "Telemedicine/Virtual Primary Care",
        "Women's Health",
        "Fertility Treatment",
      ],
      link: "https://plansource.com/partners/marketplace/health/famlee/",
    },
    {
      partner: "Givinga",
      copy: "At Givinga, we empower companies to develop modern giving solutions that align with and enhance their corporate strategy. Our philanthropic technology, trademarked Philantech®, powers charitable campaigns, employee giving and matching programs, grant management, volunteer tracking, and more to transform workplace culture, build brand affinity, and cultivate everyday philanthropy.",
      logo: "givinga-logo",
      benefits: ["Charitable Giving"],
      link: "https://plansource.com/partners/marketplace/wealth/givinga/",
    },
    {
      partner: "Grayce",
      copy: "Grayce helps employee families navigate and resolve care challenges when they need it most.",
      logo: "grayce-logo",
      benefits: [
        "Caregiver Support",
        "Employee Assistance Programs (EAP)",
        "Family Health",
      ],
      link: "https://plansource.com/health/grayce/",
    },
    {
      partner: "ID Watchdog",
      copy: "ID Watchdog was designed with law enforcement, banking, credit, and information privacy in mind. They continue to innovate solutions delivered directly to consumers and through the employee benefits marketplace.",
      logo: "id-watchdog-equifax-logo",
      benefits: ["Identity Theft", "Financial Wellness"],
      link: "https://plansource.com/partners/marketplace/wealth/id-watchdog/",
    },
    {
      partner: "IncentFit",
      copy: "IncentFit is a cost-effective solution for teams that are just getting started with workplace wellness or teams that are looking to increase engagement rates through meaningful activity. IncentFit is a powerful end-to-end solution that targets and improves all aspects of personal health and well-being. ",
      logo: "incentfit-logo",
      benefits: ["Whole Health", "Population Health"],
      link: "https://plansource.com/partners/marketplace/lifestyle-planning/incentfit/ ",
    },
    {
      partner: "ALEX by Jellyvision",
      copy: "ALEX Helps Employees Discover Affordable Care Options with Timely and Ongoing Guidance. Deliver a Personalized Benefits Experience with ALEX to increase employee Satisfaction.",
      logo: "alex-logo",
      benefits: ["Artificial Intelligence", "Navigation/Advocacy"],
      link: "https://plansource.com/partners/marketplace/asset-protection/alex-jellyvision/",
    },
    {
      partner: "Jasper Health",
      copy: "Jasper Health is a digital oncology platform that delivers psychosocial support and coaching directly to members via our Cancer Care Companion, a smart personalized planner. Tens of thousands of members today rely upon the Jasper Cancer Care Companion App to connect with caregivers, providers, and employer and health plan care teams.",
      logo: "jasper-logo",
      benefits: ["Caregiver Support", "Navigation/Advocacy", "Cancer"],
      link: "https://plansource.com/health/jasper-health/",
    },
    {
      partner: "Juno",
      copy: "Juno uses group buying power to negotiate with lenders to get the employee the best interest rates available. Think student loans, with a volume discount. Juno came into existence from their desire to make this benefit available to all future generations of students. Today, Juno is able to get the lowest rate student loans for thousands.",
      logo: "juno-logo",
      benefits: ["Financial Wellness", "Financial Education", "Student Loan"],
      link: "https://plansource.com/partners/marketplace/wealth/juno/",
    },
    {
      partner: "Kashable",
      copy: "Kashable is a financial technology company that offers a socially responsible financing solution as an employer-sponsored voluntary benefit. Utilizing innovative technology, eligible employees can access low-cost loans as a responsible alternative to high-rate credit cards, predatory lenders, or relying on their retirement savings to bridge short-term financial gaps.",
      logo: "kashable-logo",
      benefits: [
        "Financial Education",
        "Short Term Loans",
        "Financial Wellness",
      ],
      link: "https://plansource.com/wealth/kashable/",
    },
    {
      partner: "Kindly Human",
      copy: "Kindly Human is a top provider of peer-based mental well-being solutions for employers, connecting individuals dealing with everyday life struggles to empathetic, professionally trained peers who share the same experiences and cultural characteristics. With 24/7 preclinical support and on-demand technology, the platform offers members immediate help in the moment of need, without the need to navigate a complicated mental health system.",
      logo: "kindly-human-logo",
      benefits: [
        "Mental/Behavioral Health",
        "Telemedicine/Virtual Primary Care",
        "Whole Health",
      ],
      link: "https://plansource.com/partners/marketplace/health/kindly-human/",
    },
    {
      partner: "Kumanu",
      copy: "Kumanu is the first purpose-centered wellbeing company. It helps employers reduce burnout, boost engagement, improve emotional wellbeing, and builds lasting behavior change by addressing the key determinants of health-personal, social, and organizational.",
      logo: "kumanu-logo",
      benefits: [
        "Mental/Behavioral Health",
        "Population Health",
        "Whole Health",
      ],
      link: "https://plansource.com/partners/marketplace/health/kumanu/",
    },
    {
      partner: "Legal Resources",
      copy: "Legal resources is an employee benefit that provides high-quality legal services to our members, helping them to lead lives free of major legal expenses.",
      logo: "legal-resources-logo",
      benefits: ["Financial Wellness", "Identity Theft", "Legal"],
      link: "https://plansource.com/partners/marketplace/wealth/legal-resources/",
    },
    {
      partner: "LegalEASE",
      statement:
        "LegalEASE's service-focused infrastructure ensures employees have the individual help and attention they need at the time they need it most. They provide members support from the start of his/her need for legal help, through the attorney assignment process and all the way to the end of the employee's legal case.",
      copy: "Member-centric, Concierge-style of service.",
      logo: "legal-ease-logo",
      benefits: ["Legal"],
      link: "https://plansource.com/partners/marketplace/wealth/legalease/",
    },
    {
      partner: "Legalshield & ID sheidl PPLSI",
      copy: "LegalShield provides plan participants with direct access to a dedicated LegalShield Provider Law Firm who can review legal documents, prepare Wills and assist with other personal legal matters. IDShield provides 360-degree protection with identity monitoring, device and online privacy and reputation management services as well as full-service identity restoration.",
      logo: "legal-shield-logo",
      benefits: ["Identity Theft", "Legal"],
      link: "https://plansource.com/partners/marketplace/wealth/legal-shield-id-shield/",
    },
    {
      partner: "Liberty Mutual",
      copy: "Liberty Mutual offers a range of personal lines insurance products, including standard auto, homeowners, renters and condo owners, and powersports (watercraft, motorcycle, off road vehicles, motor homes and classic cars).",
      logo: "liberty-mutual-logo",
      benefits: ["Home/Auto", "Renters Insurance"],
      link: "https://plansource.com/partners/marketplace/asset-protection/liberty-mutual/",
    },
    {
      partner: "LifeGuides",
      copy: "LifeGuides helps your employees be at their best - learn, grow and thrive. When your employees thrive, your business thrives",
      logo: "life-guides-logo",
      benefits: [
        "Mental/Behavioral Health",
        "Social Determinants of Health",
        "Whole Health",
      ],
      link: "https://plansource.com/partners/marketplace/health/lifeguides/",
    },
    {
      partner: "MASA MTS",
      copy: "Emergencies are inevitable and MASA MTS provides a gap coverage that protects your family from being crippled by emergency transportation bills. MASA MTS works with ambulance companies operating in the continental United States and Canada, meaning that you will be protected from out-of-pocket expenses, regardless of which ambulance picks you up, regardless of the benefits network.",
      logo: "masa-logo",
      benefits: ["Emergency Ground & Air Ambulance Coverage"],
      link: "https://plansource.com/partners/marketplace/health/masa-mts/",
    },
    {
      partner: "Maven",
      copy: "Maven is the most comprehensive platform of its kind, providing critical support across fertility, pregnancy, adoption, parenting, and pediatrics. Since their launch, Maven has helped millions of women and families navigate their health by putting them at the center of care—where they belong.",
      logo: "maven-logo",
      benefits: [
        "Family Health",
        "Women's Health",
        "Fertility Treatment",
        "Social Determinants of Health",
      ],
      link: "https://plansource.com/partners/marketplace/health/maven-clinic/",
    },
    {
      partner: "McAfee",
      copy: "McAfee™, a trusted leader in online protection, is solving one of today's greatest risks for employers and their employees with McAfee Digital Wellness™, an all-in-one employee benefit program designed to safeguard against compromised devices, identity theft, privacy leaks and more. McAfee frees and empowers people to confidently work and live their life online safely.",
      logo: "mcafee-logo",
      benefits: ["Cyber Security", "Identity Theft"],
      link: "https://plansource.com/partners/marketplace/wealth/mcafee/",
    },
    {
      partner: "MetLife ID & Fraud Protection powered by Aura",
      copy: "MetLife Identity and Fraud Protection Powered by Aura is an employee benefit that provides personalized, all-in-one digital protection to safeguard members' identity, money and assets, family, online reputation and privacy. By bringing together security, privacy and parental controls on an intelligent platform, Aura is making the internet a safer place for employees and their families.",
      logo: "metlife-aura-logo",
      benefits: ["Identity Theft"],
      link: "https://plansource.com/partners/marketplace/wealth/metlife-id-aura/",
    },
    {
      partner: "MetLife Legal Plans",
      copy: "MetLife Legal Plan members are given easy and no-added-cost access to a network of qualified attorneys for the most common legal situations - from estate planning to real estate transactions to traffic incidents.",
      logo: "metlife-logo",
      benefits: ["Legal"],
      link: "https://plansource.com/partners/marketplace/wealth/metlife-legal/",
    },
    {
      partner: "MetLife Pet Insurance",
      copy: "Affordable, extensive coverage from MetLife Pet Insurance means your pet can get the care they need to help them live a long and healthy life — and you can stay worry-free.",
      logo: "metlife-aura-logo",
      benefits: ["Pet Insurance"],
      link: "https://plansource.com/marketplace/wealth/metlife-pet",
    },
    {
      partner: "Trend Micro",
      copy: "Trend Micro helps employees and their families secure their digital lives with comprehensive identity and device protection.",
      logo: "trend-micro-logo",
      benefits: ["Identity Theft", "Cyber Security"],
      link: "https://plansource.com/partners/marketplace/wealth/trend-micro/",
    },
    {
      partner: "Modern Health",
      copy: "Modern Health provides employers with a fully integrated ecosystem of mental health solutions designed to drive clinical outcomes and organizational impact across global workforces.",
      logo: "modern-health-logo",
      benefits: [
        "Mental/Behavioral Health",
        "Navigation/Advocacy",
        "Population Health",
      ],
      link: "https://plansource.com/partners/marketplace/",
    },
    {
      partner: "Nectar",
      copy: "Nectar helps employers provide recognition for great work, the promotion of core values, community, and employee reward redemption ─ all in one simple employee recognition platform.",
      logo: "nectar-logo",
      benefits: ["Employee Rewards Programs"],
      link: "https://plansource.com/lifestyle-planning/nectar/",
    },
    {
      partner: "Nest Collaborative",
      copy: "Nest Collaborative provides prenatal and postpartum breastfeeding support through virtual video visits with board-certified lactation consultants at no cost to the employee. Their program aims to reduce healthcare costs associated with suboptimal breastfeeding while supporting healthy transitions back to work. Appointments offered day and night, 7 days a week.",
      logo: "nest-collaborative-logo",
      benefits: ["Women's Health", "Telemedicine/Virtual Primary Care"],
      link: "https://plansource.com/partners/marketplace/health/nest-collaborative/",
    },
    {
      partner: "Norton LifeLock",
      copy: "A global leader in consumer Cyber Safety, protecting and empowering people to live their digital lives safely, NortonLifeLock is a trusted ally in an increasingly complex and connected world.",
      logo: "norton-life-lock-logo",
      benefits: ["Cyber Security", "Identity Theft"],
      link: "https://plansource.com/partners/marketplace/wealth/nortonlifelock/",
    },
    {
      partner: "Onduo",
      copy: "Onduo by Verily is a virtual, chronic care platform for people living with type 2 diabetes,  and hypertension that brings together the promise of technology and data with human connection to deliver personalized, precision care and empower people toward healthier behaviors. ",
      logo: "onduo-logo",
      benefits: ["Diabetes", "Chronic Disease Management"],
      link: "https://plansource.com/marketplace/health/onduo",
    },
    {
      partner: "Parentaly",
      copy: "Parentaly is an employee benefit that combines career coaching and content to help expecting parents navigate the parental leave experience, minimizing the stress and career disruption for employees and helping employers avoid the decreased productivity and attrition risk associated with longer (6+ week) leave policies.",
      logo: "parentaly-logo",
      benefits: ["Child Care"],
      link: "https://plansource.com/partners/marketplace/health/parentaly/",
    },
    {
      partner: "Pathfinder Health (CheckUps)",
      copy: "Has developed the next generation in biometric screening & prevention. We utilize & optimize an existing covered benefit (no added costs) in your health plan: The Checkup with a Doctor, delivered virtually or onsite. The doctor is the best way to engage employees, because they direct each employee individually into the appropriate program for them personally.",
      logo: "pathfinder-health-logo",
      benefits: ["Telemedicine/Virtual Primary Care"],
      link: "https://plansource.com/partners/marketplace/health/pathfinder-health/",
    },
    {
      partner: "Pet Benefit Solutions",
      copy: "Pet Benefit Solutions brings affordable, easy-to-use pet care plans to employees nationwide. From discounts on vet visits and pet products to 24/7 live veterinary support and pet ID tags, Total Pet Plan from Pet Benefit Solutions is the only pet benefits package with coverage for everything pets need - all for less than 40 cents a day!",
      logo: "pet-benefits-solutions-logo",
      benefits: ["Pet Insurance"],
      link: "https://plansource.com/partners/marketplace/wealth/pet-benefit-solutions/",
    },
    {
      partner: "PTO Exchange",
      copy: "PTO Exchange is the leading benefits platform that allows employees to self-direct the value of their unused paid time off (PTO) for other needs and causes, including retirement, student loans, charitable donations, and more. PTO Exchange increases employee engagement and retention all while reducing balance sheet liabilities and strengthening culture and community.",
      logo: "pto-exchange-logo",
      benefits: ["Charitable Giving", "Time Off Perks", "Financial Wellness"],
      link: "https://plansource.com/partners/marketplace/wealth/pto-exchange/",
    },
    {
      partner: "Paytient",
      copy: "Paytient helps employees access and afford care, improving retention, health equity, and financial wellness while reducing costs.",
      logo: "paytient-logo",
      benefits: [
        "Financial Wellness",
        "Social Determinants of Health",
        "Whole Health",
      ],
      link: "https://plansource.com/health/paytient/",
    },
    {
      partner: "RethinkCare",
      copy: "RethinkCare provides evidence-based tools, trainings and clinical support for working parents, caregivers and their families.",
      logo: "rethinkcare-logo",
      benefits: ["Caregiver Support", "Mental/Behavioral Health", "Sleep"],
      link: "https://plansource.com/marketplace/health/rethinkcare",
    },
    {
      partner: "Rightway",
      copy: "Rightway's navigation platform serves as a starting point for all healthcare needs. Through its intuitive mobile-first app, combined with live clinical guides, Rightway navigates members through their entire care journey, resulting in industry-leading engagement and meaningful cost savings.",
      logo: "rightway-logo",
      benefits: [
        "Navigation/Advocacy",
        "Telemedicine/Virtual Primary Care",
        "Transparency/Quality Cost",
      ],
      link: "https://plansource.com/partners/marketplace/health/rightway/",
    },
    {
      partner: "SavvyFi",
      copy: "SavvyFi is a user-friendly fintech platform that makes it easy for employers to provide college savings and student loan benefits to their employees. Because the company's platform is “zero-touch” to HR - without any complicated systems, integrations or paperwork - SavvyFi unlocks education financing capabilities for all companies, big or small.",
      logo: "savvyfi-logo",
      benefits: ["Student Loan", "529 Plans", "Financial Wellness"],
      link: "https://plansource.com/partners/marketplace/wealth/savvyfi/",
    },
    {
      partner: "Sword Health",
      copy: "SWORD Health is the world's fastest growing digital musculoskeletal (MSK) care provider, on a mission to free two billion people from acute, chronic and post-surgical pain. It is the first digital solution to pair its members with a licensed physical therapist and the SWORD Health Digital Therapist, to deliver a clinically proven and personalized treatment plan.",
      logo: "sword-health-logo",
      benefits: [
        "Chronic Disease Management",
        "Family Health",
        "Musculoskeletal",
      ],
      link: "https://plansource.com/partners/marketplace/health/sword-health/",
    },
    {
      partner: "TaskHuman",
      copy: "TaskHuman connects you to a global network of professional coaches via 1:1 video calls, in-platform content, and group coaching session unlocking your fullest potential, improving performance, and providing support – instantly.",
      logo: "taskhuman-logo",
      benefits: ["Food and Nutrition", "Financial Wellness", "Whole Health"],
      link: "https://plansource.com/partners/marketplace/health/taskhuman/",
    },
    {
      partner: "TempoPay",
      copy: "TempoPay pays for out-of-pocket healthcare costs upfront, so members can get the services they need when they need them. After receiving care or paying for healthcare products, members arrange a repayment plan that works with their financial resources and timing. Employees have an extra layer of financial security to pay for out-of-pocket costs and unexpected medical bills on their terms.",
      logo: "tempopay-logo",
      benefits: [
        "Navigation/Advocacy",
        "Financial Wellness",
        "Social Determinants of Health",
      ],
      link: "https://plansource.com/partners/marketplace/health/tempopay/",
    },
    {
      partner: "United Pet Care",
      copy: "United Pet Care (UPC) is America's premier pet care membership program. They make pet benefits easy, affordable, and accessible to all pet parents by offering members savings on every visit to the vet—as well as pet care, televet services, and more.",
      logo: "united-pet-care-logo",
      benefits: ["Pet Insurance"],
      link: "https://plansource.com/partners/marketplace/wealth/united-pet-care/",
    },
    {
      partner: "Unsaddl",
      copy: "Education benefits for a modern workforce.",
      logo: "unsaddl-logo",
      benefits: ["Student Loan", "Financial Wellness", "529 Plans"],
      link: "https://plansource.com/wealth/unsaddl/",
    },
    {
      partner: "UrbanSitter",
      copy: "From booking backup child care in minutes to finding a senior care companion, UrbanSitter's Corporate Care Program helps your employees quickly find qualified, background-checked caregivers, so they can stay focused on work and their well-being. UrbanSitter takes away the hassle of finding care with nationwide coverage available 24/7 and inclusive care options: child care, household services, senior care, and pet sitting.",
      logo: "urbansitter-logo",
      benefits: ["Child Care", "Caregiver Support"],
      link: "https://plansource.com/partners/marketplace/health/urbansitter/",
    },
    {
      partner: "Vori Health",
      copy: "Vori Health is an all-inclusive health care provider practicing a holistic, integrated approach starting with musculoskeletal care.",
      logo: "vori-health-logo",
      benefits: [
        "Chronic Disease Management",
        "Musculoskeletal Management",
        "Second Opinion",
      ],
      link: "https://plansource.com/health/vori-health/",
    },
    {
      partner: "Wagmo",
      copy: "Wagmo is a tech-first pet wellness company built with pet parents in mind. Wagmo alleviates the financial stress of routine and emergency pet care, from grooming to essential maintenance, cancer treatments and emergencies, without having to compromise.",
      logo: "wagmo-logo",
      benefits: ["Pet Insurance"],
      link: "https://plansource.com/partners/marketplace/wealth/wagmo/",
    },
    {
      partner: "Wysa",
      copy: "Wysa is a mental health and well-being platform that offers 24/7 access to AI-guided self-help, unlimited human-coaching support and 150+ on-demand self-help tools all underpinned by emotionally intelligent conversational AI that guides people towards the right help at the right time.",
      logo: "wysa-logo",
      benefits: [
        "Artificial Intelligence",
        "Mental/Behavioral Health",
        "Population Health",
        "Sleep",
      ],
      link: "https://plansource.com/partners/marketplace/health/wysa/",
    },
    {
      partner: "Wellness Coach",
      copy: "Wellness Coach is an all-encompassing employee wellbeing solution designed to improve individual wellness and team-wide engagement through personal and team coaching, unique wellness challenges, and thoughts of on-demand sessions, all conveniently accessible through a user-friendly platform with apps for Slack, Microsoft Teams and Zoom.",
      logo: "wellnes-coach-logo",
      benefits: [
        "Employee Assistance Programs (EAP)",
        "Employee Rewards Programs",
        "Obesity Management",
      ],
      link: "https://plansource.com/partners/marketplace/health/wellness-coach/",
    },
    {
      partner: "Zizzl",
      copy: "Zizzl health is a state-of-the-art ICHRA solution that helps employers offer budget-friendly, hassle-free health insurance to employees. zizzl health ensures that companies are financially protected while helping employees find individual coverage that caters directly to their unique needs.",
      logo: "zizzle-health-logo",
      benefits: ["TPA"],
      link: "https://plansource.com/partners/marketplace/health/zizzl-health/",
    },
  ],
};
