<template>
    <section class="landing-page bg-primary">
        <div class="heading-container">
            <div class="container">
                <div class="content" ref="content">
                    <h1 class="heading">{{heading}}</h1>
                    <h2 class="subheading">{{subheading}}</h2>
                    <p class="copy">{{copy}}</p>
                    <Button
                        btn-class="btn-primary"
                        btn-text="Begin"
                        btn-route="/personalize"
                        :disabled="false"
                    />
                </div>
                <div class="video-container" ref="video1">
                    <div class="video">
                        <video 
                            disableRemotePlayback
                            playsinline
                            muted
                            loop
                            autoplay
                        >
                            <source src="@/assets/video/homepage.mp4" type="video/mp4">
                        </video>
                    </div>
                </div>
            </div>
            <img 
                class="video-img-1"
                src="@/assets/images/landing-page-img-1.png"
                alt="Header image 1"
                ref="img1"
            />
            <img 
                class="video-img-3"
                src="@/assets/images/landing-page-img-3.png"
                alt="Header image 2"
                ref="img2"
            />
            <img 
                class="orange-sprinkles"
                src="@/assets/images/svg/orange-sprinkles-right.svg"
                alt="Orange sprinkles image"
                ref="sprinkles"
            />
            <div class="video-container-tertiary" ref="video2">
                <div class="video">
                    <video 
                        disableRemotePlayback
                        playsinline
                        muted
                        loop
                        autoplay
                    >
                        <source src="@/assets/video/homepage.mp4" type="video/mp4">
                    </video>
                </div>
            </div>
        </div>
    </section>
</template>

<script>

// Helpers
import helpers from '@/helpers/helpers'

// Components
import Button from '@/components/Button.vue'

// Animations
import { fadeIn } from '@/helpers/animations'

// Data
import homeCopy from '@/data/site-copy'
const { heading, subheading, copy } = homeCopy.homeCopy

export default {
    // eslint-disable-next-line
    name: 'home',
    components: { Button },
    computed: {
        heading() { return heading },
        subheading() { return subheading },
        copy() { return copy }
    },
    created() {
        helpers.adjustHeight(true)
    },
    mounted() {
        fadeIn(this.$refs.content, 0.3, 0.7)
        fadeIn(this.$refs.video1, 0.5, 0.7)
        fadeIn(this.$refs.video2, 0.5, 0.7)
        fadeIn(this.$refs.img1, 0.9, 0.7)
        fadeIn(this.$refs.img2, 1.1, 0.7)
        fadeIn(this.$refs.sprinkles, 1.3, 0.7)
    }
}
</script>

<style lang="scss" scoped>

@import '@/assets/scss/mixins.scss';
@import '@/assets/scss/functions.scss';

.landing-page {
    justify-content: flex-start;
    overflow: hidden;

    .content,
    .video-container,
    .video-container-tertiary,
    .video-img-1,
    .video-img-3,
    .orange-sprinkles {
        opacity: 0;
    }

    @include respond-to(desktop-wide-and-up) {
        justify-content: center;
    }

    .heading-container {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        position: relative;

        .container {

            @include respond-to(wide-and-up) {
                margin-left: 165px;
            }
        }

        @include respond-to(dektop-semi-wide-and-up) {
            max-width: 1103px;
        }

        .container {

            @include respond-to(dektop-semi-wide-and-up) {
                justify-content: space-between;
            }

            .content {
                @include respond-to(wide-and-up) {
                    margin-right: 50px;
                }
            }
        }
    }

    .orange-sprinkles {
        position: absolute;
        top: 70px;
        right: 0;
        width: 165px;
        height: 155px;

        @include respond-to(medium-and-up) {
          width: 280px;
          height: 271px;
        }
    }

    .video-img-1,
    .video-img-3 {
        position: absolute;
        z-index: 1;
    }

    .video-img-1 {
        top: 31px;
        right: 0;
        width: 142px;
        height: 142px;
        display: block;

        @include respond-to(tiny-and-up) {
            right: 0;
        }

        @include respond-to(medium-and-up) {
            width: 250px;
            height: 250px;
            top: 18px;
            right: 80px;
        }

        @media only screen and (min-width: 1800px) and (max-height: 1050px) {
            display: none;
        }
    }

    .video-img-3 {
        display: none;

        @include respond-to(desktop-wide-and-up) {
            display: block;
            bottom: -50px;
            right: -100px;
            width: 430px;
            height: 420px;
        }

        @include respond-to(dektop-semi-wide-and-up) {
            right: -200px;
        }
        
        @media only screen and (max-height: 1050px) {
            width: 250px;
            height: 250px;
        }
    }

    .video-container-tertiary {
        display: block;
        position: absolute;
        display: flex;
        width: 170px;
        height: 170px;
        justify-content: center;
        bottom: -20px;
        right: -30px;

        @include respond-to(tiny-and-up) {
            width: 200px;
            height: 200px;
        }

        @include respond-to(very-small-and-up) {
            width: 250px;
            height: 250px;
            bottom: -40px;
            right: -35px;
        }

        @include respond-to(medium-and-up) {
            width: 323px;
            height: 323px;
            bottom: -45px;
            right: -45px;
        }

        @include respond-to(desktop-wide-and-up) {
            bottom: unset;
            right: unset;
            display: none;
        }

        .video {
            display: inline-block;
            width: 170px;
            height: 170px;
            position: relative;
            overflow: hidden;

            @include respond-to(tiny-and-up) {
                width: 200px;
                height: 200px;
            }

            @include respond-to(very-small-and-up) {
                width: 250px;
                height: 250px;
            }   

            @include respond-to(medium-and-up) {
                width: 323px;
                height: 323px;
            }

            @include respond-to(desktop-wide-and-up) {
                bottom: unset;
                right: unset;
                display: none;
            }

            video {
                position: absolute;
                object-fit: cover;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                border-radius: 50%;
            }
        }
    }

    .container {
        display: flex;
        flex-direction: row;

        .video-container {
            display: none;
            position: relative;

            @include respond-to(desktop-wide-and-up) {
                display: flex;
                width: 325px;
                height: 414px;
                justify-content: center;
            }

            .video {
                @include respond-to(desktop-wide-and-up) {
                    display: inline-block;
                    width: 323px;
                    height: 323px;
                    position: relative;
                    overflow: hidden;
                }

                video {
                    position: absolute;
                    object-fit: cover;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                }
            }
        }

        .content {

            .heading,
            .subheading,
            .copy {
                color: var(--peq-white);
            }

            .heading {
                margin: 0 0 15px 0;
                
                @include respond-to(medium-and-up) {
                    margin: 0 0 30px 0;
                }
            }

            .subheading {
                max-width: 190px;
                margin: 0 0 24px 0;

                @include respond-to(tiny-and-up) {
                    max-width: 260px;
                }

                @include respond-to(medium-and-up) {
                    max-width: 520px;
                }
            }

            .copy {
                max-width: 200px;
                margin: 0 0 39px 0;

                @include respond-to(tiny-and-up) {
                    max-width: 300px;
                }

                @include respond-to(medium-and-up) {
                    max-width: 450px;
                }
            }
        }
    }

    p {
        color: var(--peq-white);
    }
}

</style>
