<template>
    <div class="slider-container" ref="slider">
        <ul class="slider-points">
            <li
                :style="`left: ${point.leftValue}%`"
                v-for="point in constructedPoints" 
                :key="point.name" 
                @click="
                    updateSelection(point.name, formItem); 
                    updateHandlePosition(point.leftValue - minusValueHandle);
                "
            >
            </li>
            <li
                :style="{left: 0 + '%'}"
                class="slider-handle grabbable" 
                ref="sliderHandle"
            ></li>
        </ul>
        <ul class="slider-labels" translate="no">
            <li 
                v-for="point in constructedPoints" 
                :key="point.name" 
                :style="`left: ${point.leftValueText}%`"
            >
                <p>{{point.name}}</p>
            </li>
        </ul>
    </div>
</template>

<script>

// Helpers
import helpers from '@/helpers/helpers'

// Modules
import * as Hammer from 'hammerjs'

export default {
    // eslint-disable-next-line
    name: 'slider',
    props: {
        sliderPoints: { type: Array, default() { return [] } },
        updateSelection: { type: Function, default: () => {} },
        selection: { type: String, default: '' },
        formItem: { type: String, default: '' },
        snapTo: { type: Boolean, default: true },
        minusValue: { type: Number, default: 0 },
        minusValueHandle: { type: Number, default: 0 },
        initOnMount: { type: Boolean, default: true },
        isModal: { type: Boolean, default: false }
    },
    data() {
        return {
            leftValue: 100 / (this.sliderPoints.length - 1),
            constructedPoints: null
        }
    },
    methods: {
        updateHandlePosition(leftValue) {
            this.$refs.sliderHandle.style.left = "" + leftValue + "%"
            this.$refs.sliderHandle.classList.add('animating')
            this.initSlider((leftValue - this.minusValueHandle) / 100)
        },
        initSlider(leftValue) {
            const handle = this.$refs.sliderHandle
            const hammertime = new Hammer.Manager(handle)
            const sliderWidth = this.$refs.slider.offsetWidth
            const points = this.constructedPoints
            const updateSelection = this.updateSelection
            const snapTo = this.snapTo
            const minusValueHandle = this.minusValueHandle

            let currentPosition = null
            let startingPosition = leftValue ? leftValue : parseInt(handle.style.left)

            hammertime.add(new Hammer.Pan({ threshold: 0, pointers: 0 }))

            hammertime.on('pan', function(ev) {
                handle.classList.remove('animating')
                currentPosition = (startingPosition * 100) + 100 * ev.deltaX / sliderWidth

                if (currentPosition > 98) {
                    currentPosition = 98
                }

                if (currentPosition < -1) {
                    currentPosition = 0
                }

                handle.style.left = "" + currentPosition + "%"
                handle.classList.add('animated')
            })

            hammertime.on('panend', function() {
                const values = []

                points.forEach(element => {
                    values.push(element.leftValue)
                })

                const closest = values.reduce((a, b) => {
                    return Math.abs(b - currentPosition) < Math.abs(a - currentPosition) ? b : a
                })

                if (snapTo) {
                    handle.classList.add('animating')
                    handle.style.left = "" + closest - minusValueHandle + "%"
                    startingPosition = closest / 100
                    points.forEach(point => {
                        if (point.leftValue === closest) {
                            updateSelection(point.name)
                        }
                    })
                } else {
                    startingPosition = parseInt(handle.style.left)
                    updateSelection(((startingPosition /= Math.pow(10, 2)) * 6000 + 6000).toString())
                }

                handle.classList.remove('animated')
            })
        }
    },
    computed: {
        modalOpen() {
            return this.$store.state.modalOpen
        }
    },
    watch: {
        modalOpen(newValue) {
            if (newValue) {
                this.initSlider()
            }
        }
    },
    created() {
        window.addEventListener('resize', this.initSlider)
        this.constructedPoints = helpers.constructSliderPoints(this.sliderPoints, this.leftValue, this.isModal)
    },
    mounted() {
        this.constructedPoints.forEach(point => {
            if (point.name === this.selection) {
                this.$refs.sliderHandle.style.left = "" + point.leftValue + "%"
            }
        })

        if (this.initOnMount) {
            this.initSlider()
        }
    },
    destroyed() {
        window.removeEventListener('resize', this.initSlider)
    }
}

</script>

<style lang="scss" scoped>

@import '@/assets/scss/mixins.scss';
@import '@/assets/scss/functions.scss';

.slider-container {
    width: 100%;
    margin-bottom: 50px;

    @include respond-to(medium-and-up) {
        width: 100%;
        margin-right: auto;
        margin-left: auto;
    }

    .slider-points {
        height: 7px;
        padding: 0;
        background: var(--peq-green);
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;

        li {
            position: relative;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background-color: var(--peq-green);
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;

            &:hover {
                cursor: pointer;
            }

            &:before {
                position: absolute;
                content: "";
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background-color: var(--peq-dark-green);
            }
        }

        .slider-handle {
            position: absolute;
            width: 40px;
            height: 40px;
            background: var(--peq-navy);

            &.animating {
                transition-property: left;
                transition-duration: 0.3s;
            }

            &:before,
            &:after {
                position: absolute;
                color: var(--peq-white);
                width: auto;
                height: auto;
                font-size: rem(16);
                border-radius: 0;
                background-color: unset;
            }

            &:before {
                content: "<";
                left: 8px;
            }

            &:after {
                content: ">";
                right: 8px;
            }

            &.grabbable {
                cursor: move;
                cursor: grabbing;
                cursor: -moz-grab;
                cursor: -webkit-grab;
            }

            &.animated {
                cursor: grabbing;
                cursor: -moz-grabbing;
                cursor: -webkit-grabbing;
            }
        }
    }

    .slider-labels {
        display: flex;
        padding: 0;
        width: 100%;
        position: relative;

        li {
            position: absolute;
            text-align: left;
            max-width: 50px;

            @include respond-to(medium-and-up) {
                width: 100%;
                max-width: none;
            }
            
            p {
                font-size: rem(12);
            }
        }
    }
}

</style>