<template>
    <section class="bg-tertiary flex-group-column margin-auto question-1" id="section">
        <div class="container flex-group-column" ref="container" id="container">
            <p class="question-number">Question 1 of 6</p>
            <h3 ref="heading">{{heading}}</h3>
            <Slider 
                :sliderPoints="sliderPoints"
                :updateSelection="updateSelection"
                :selection="question1Form.selection"
                :snap-to="true"
                :minusValue="2"
                :minus-value-handle="2"
            />
        </div>
        <div class="flex-group button-group" ref="buttons" id="button">
            <Button 
                btn-class="btn-secondary"
                btn-text="Back"
                btn-route="/personalize"
                :disabled="false"
            />
            <Button 
                btn-class="btn-primary" 
                btn-route="/question-2" 
                btn-text="Continue"
                :form-data="formData"
                form-key="question1Form"
                :disabled="false"
                :animation-items="['#container']"
            />
        </div>
        <Modal>
            <div class="benefit-icon-container">
                <img class="icon" :src="getImagePath" alt="Benefit icon" />
            </div>
            <div class="benefit-content">
                <h4>{{tooltip}}</h4>
                <p>{{tooltipCopy}}</p>
            </div>
        </Modal>
    </section>
</template>

<script>

// Helpers
import helpers from '@/helpers/helpers'

// Components
import Button from '@/components/Button.vue'
import Slider from '@/components/Slider.vue'
import Modal from '@/components/Modal.vue'

// Animations
import { fadeInUp } from '@/helpers/animations'

// Data
import question1 from '@/data/site-copy'

const {
    heading,
    sliderPoints,
    icon,
    tooltip,
    tooltipCopy
} = question1.question1

export default {
    name: 'question-1',
    components: { Button, Slider, Modal },
    data() {
        return {
            question1Form: {
                selection: typeof(this.$store.state.globalFormData.question1Form.selection) !== 'undefined' ?
                this.$store.state.globalFormData.question1Form.selection :
                sliderPoints[0]
            },
            getImagePath: helpers.getImagePath(icon, 'icons')
        }
    },
    computed: {
        formData() { return this.question1Form.selection ? this.question1Form : {} },
        heading() { return heading },
        sliderPoints() { return sliderPoints },
        tooltip() { return tooltip },
        tooltipCopy() { return tooltipCopy }
    },
    methods: {
        updateSelection(selection) {
            this.question1Form.selection = selection
        },
        createTooltip() {
            const heading = this.$refs.heading
            const text = heading.innerHTML
            const tip = tooltip.toLowerCase()
            const updatedText = text.replace(tip, `<span class="tooltip" id="tooltip">${tip}</span>`)
            heading.innerHTML = updatedText
        },
        tooltipClick() {
            this.$store.commit('toggleModal', {
                modalOpen: !this.modalOpen
            })
        }
    },
    created() {
        helpers.adjustHeight(true)
    },
    mounted() {
        this.createTooltip()
        fadeInUp(this.$refs.container, 0.1, 0.7)
        fadeInUp(this.$refs.buttons, 0.1, 0.7)
        const tooltipClick = this.tooltipClick
        const tooltipElement = document.getElementById('tooltip')
        tooltipElement.addEventListener("click", tooltipClick, false)
    },
}
</script>

<style lang="scss" scoped>

@import '@/assets/scss/mixins.scss';
@import '@/assets/scss/functions.scss';

section {

    h3 {
        margin-bottom: 30px;
    }

    .slider-container {
        width: 90%;

        @include respond-to(medium-and-up) {
            width: 95%;
        }
    }
}

</style>