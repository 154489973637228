import { gsap } from 'gsap'

export const fadeUpBounce = (element, delay, duration) => {
    return gsap.from(
        element, 
        {y: 100, opacity: 0, duration: duration, delay: delay, repeat: 0, ease: "back.inOut(10)"}
    )
}

export const fadeIn = (element, delay, duration) => {
    return gsap.fromTo(
        element,
        {opacity: 0, ease: "Expo.easeInOut"},
        {opacity: 1, duration: duration, delay: delay, repeat: 0}
    )
}

export const fadeOut = (element, delay, duration) => {
    return gsap.fromTo(
        element,
        {opacity: 1, ease: "back.inOut(10)"},
        {opacity: 0, duration: duration, delay: delay, repeat: 0}
    )
}

export const fadeInUp = (element, delay, duration) => {
    return gsap.fromTo(
        element,
        {opacity: 0, y: 30, ease: "back.inOut(10)"},
        {opacity: 1, y: 0, duration: duration, delay: delay, repeat: 0}
    )
}
