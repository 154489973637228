<template>
  <section class="bg-secondary margin-auto" ref="section">
    <div class="container flex-group-column" ref="container" id="container">
      <h3>{{ heading }}</h3>
      <p>{{ subheading }}</p>
      <div class="form-container">
        <input
          :class="`${
            this.$store.state.errorFields.firstName &&
            !personalizeForm.firstName
              ? 'error-input'
              : ''
          }`"
          placeholder="Name"
          :value="personalizeForm.firstName"
          @input="(event) => (personalizeForm.firstName = event.target.value)"
        />
        <input
          :class="`${
            this.$store.state.errorFields.lastName && !personalizeForm.lastName
              ? 'error-input'
              : ''
          }`"
          placeholder="Last Name"
          :value="personalizeForm.lastName"
          @input="(event) => (personalizeForm.lastName = event.target.value)"
        />
        <input
          :class="`${
            (this.$store.state.errorFields.workEmail &&
              !personalizeForm.workEmail) ||
            (this.$store.state.errorFields.workEmailRegex &&
              personalizeForm.workEmail)
              ? 'error-input'
              : ''
          }`"
          placeholder="E-mail"
          :value="personalizeForm.workEmail"
          @input="(event) => (personalizeForm.workEmail = event.target.value)"
        />
        <p
          v-if="
            Object.keys(this.$store.state.errorFields).length !== 0 &&
            this.$store.state.errorFields.workEmailRegex
          "
          class="error-message"
        >
          Please provide a valid business email.
        </p>
        <input
          :class="`${
            this.$store.state.errorFields.companyName &&
            !personalizeForm.companyName
              ? 'error-input'
              : ''
          }`"
          placeholder="Company Name"
          :value="personalizeForm.companyName"
          @input="(event) => (personalizeForm.companyName = event.target.value)"
        />
      </div>
      <div id="button">
        <Button
          btn-class="btn-primary"
          btn-route="/loading-screen-1"
          btn-text="Continue"
          :form-data="formData"
          form-key="personalizeForm"
          :local-query-params="constructedQueryParams"
          :disabled="!formHasData"
          :animation-items="['#container']"
        />
      </div>
      <p class="disclaimer text-center">{{ disclaimer }}</p>
    </div>
    <img
      class="orange-sprinkles-left"
      src="@/assets/images/svg/orange-sprinkles-left.svg"
      alt="Orange sprinkles image"
      ref="sprinkles1"
    />
    <img
      class="orange-sprinkles-right"
      src="@/assets/images/svg/orange-sprinkles-right.svg"
      alt="Orange sprinkles image"
      ref="sprinkles2"
    />
  </section>
</template>

<script>
// Helpers
import helpers from "@/helpers/helpers";

// Components
import Button from "@/components/Button.vue";

// Animations
import { fadeInUp, fadeIn } from "@/helpers/animations";

// Data
import personalizeCopy from "@/data/site-copy";
const { heading, subheading, disclaimer } = personalizeCopy.personalizeCopy;

export default {
  // eslint-disable-next-line
  name: "personalize",
  components: { Button },
  data() {
    return {
      personalizeForm: {
        firstName: this.$store.state.globalFormData.personalizeForm.firstName
          ? this.$store.state.globalFormData.personalizeForm.firstName
          : "",
        lastName: this.$store.state.globalFormData.personalizeForm.lastName
          ? this.$store.state.globalFormData.personalizeForm.lastName
          : "",
        workEmail: this.$store.state.globalFormData.personalizeForm.workEmail
          ? this.$store.state.globalFormData.personalizeForm.workEmail
          : "",
        companyName: this.$store.state.globalFormData.personalizeForm
          .companyName
          ? this.$store.state.globalFormData.personalizeForm.companyName
          : "",
      },
      constructedQueryParams: {},
    };
  },
  computed: {
    formHasData() {
      return (
        this.personalizeForm.firstName !== "" &&
        this.personalizeForm.lastName !== "" &&
        this.personalizeForm.workEmail !== "" &&
        this.personalizeForm.companyName !== ""
      );
    },
    formData() {
      return this.formHasData ? this.personalizeForm : {};
    },
    heading() {
      return heading;
    },
    subheading() {
      return subheading;
    },
    disclaimer() {
      return disclaimer;
    },
  },
  methods: {
    constructQueryParams() {
      const params = {};
      if (this.personalizeForm.firstName !== "") {
        params["firstName"] = this.personalizeForm.firstName;
      }
      this.constructedQueryParams = params;
    },
  },
  watch: {
    "personalizeForm.firstName": function () {
      this.constructQueryParams();
    },
  },
  created() {
    helpers.adjustHeight(true);
  },
  mounted() {
    this.constructQueryParams();
    fadeInUp(this.$refs.container, 0.1, 0.7);
    fadeIn(this.$refs.sprinkles1, 0.5, 0.7);
    fadeIn(this.$refs.sprinkles2, 0.7, 0.7);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mixins.scss";
@import "@/assets/scss/functions.scss";

section {
  p {
    margin-bottom: 26px;
  }

  .form-container {
    @include respond-to(medium-and-up) {
      margin-bottom: 40px;
    }
  }

  h3 {
    max-width: 275px;
    margin-bottom: 15px;

    @include respond-to(medium-and-up) {
      max-width: none;
      margin-bottom: 25px;
    }
  }

  .orange-sprinkles-left,
  .orange-sprinkles-right {
    position: absolute;
    display: none;

    @include respond-to(medium-and-up) {
      width: 280px;
      height: 271px;
      display: block;
    }
  }

  .orange-sprinkles-left {
    top: 71px;
    left: 0;
  }

  .orange-sprinkles-right {
    bottom: 102px;
    right: 0;
  }

  .disclaimer {
    font-size: rem(12);
    margin-top: 26px;
    max-width: 440px;
    color: var(--peq-off-black);

    @include respond-to(tiny-and-down) {
      max-width: 325px;
    }
  }
}
</style>
