const helpers = {
  formValidation(key, data) {
    // eslint-disable-next-line
    const regex = /^([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22))*\x40([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d))*$/;

    const specialCharactersRegex = /^[a-zA-Z0-9.@_,-]*$/;

    const { firstName, lastName, workEmail, companyName } = data;

    let valid = false;
    let errorFields = {};

    if (key === "personalizeForm") {
      if (
        firstName &&
        lastName &&
        workEmail &&
        regex.test(workEmail) &&
        specialCharactersRegex.test(workEmail) &&
        this.isBusinessEmail(workEmail) &&
        companyName
      ) {
        valid = true;
      }

      if (
        workEmail &&
        (!regex.test(workEmail) || !specialCharactersRegex.test(workEmail) || !this.isBusinessEmail(workEmail))
      ) {
        errorFields.workEmailRegex = true;
      }
    }

    if (
      key === "question1Form" ||
      key === "question2Form" ||
      key === "question3Form" ||
      key === "question4Form" ||
      key === "question5Form" ||

      key === "question6Form" 

    ) {
      valid = true;
    }
    return { valid, errorFields };
  },
  isBusinessEmail(email) {
    const rejectEmailDomainList = [
      "gmail.com",
      "yahoo.com",
      "hotmail.com",
      "msn.com",
      "comcast.net",
      "live.com",
      "rediffmail.com",
      "ymail.com",
      "outlook.com",
      "icloud.com"
    ];
    const lowerCaseEmail = email.toLowerCase();

    if (
      rejectEmailDomainList.some((rejectEmailDomain) =>
        lowerCaseEmail.includes(rejectEmailDomain)
      )
    ) {
      return false;
    }

    return true;
  },
  getImagePath(path, folder) {
    let imagePath = "";
    if (path !== "" && folder) {
      imagePath = require(`@/assets/images/${folder}/${path}.svg`);
    }
    return imagePath;
  },
  constructSliderPoints(sliderPoints, leftValue, isModal) {
    const points = [];

    sliderPoints.forEach((element, index) => {
      const point = {};

      point.name = element;
      point.leftValue = leftValue * index + 1;
      point.leftValueText = leftValue * index - 3;

      if (index === 0) {
        point.name = element;
        point.leftValue = 0;
        point.leftValueText = 0;
        if (isModal) {
          point.leftValueText = 0;
        }
      }

      if (index === sliderPoints.length - 1) {
        point.name = element;
        point.leftValue = 98;
        point.leftValueText = 96;
        if (isModal) {
          point.leftValueText = 95;
        }
      }
      points.push(point);
    });
    return points;
  },
  constructSlides(filteredBenefits, itemsPerSlide) {
    const slides = [];

    for (
      var i = 0;
      i < Math.ceil(filteredBenefits.length / itemsPerSlide);
      i++
    ) {
      slides.push({
        number: i + 1,
        slide: filteredBenefits.slice(
          i * itemsPerSlide,
          itemsPerSlide * (i + 1)
        ),
      });
    }
    return slides;
  },
  adjustHeight(addClass) {
    const root = document.getElementsByTagName("html")[0];

    if (addClass && !root.classList.contains("height-full")) {
      document.body.classList.add("height-full");
      root.classList.add("height-full");
    }

    if (!addClass && root.classList.contains("height-full")) {
      document.body.classList.remove("height-full");
      root.removeAttribute("class", "height-full");
    }
  },
};

export default helpers;
