<template>
    <div @click="toggleModal($event.target)" :class="`modal-container ${modalOpen ? 'active' : ''}`">
        <div class="modal bg-modal" ref="modal">
            <div class="plus" @click.stop="toggleModal($event.target)"></div>
            <slot></slot>
        </div>
    </div>
</template>

<script>

// Animations
import { fadeIn } from '@/helpers/animations'

export default {
    // eslint-disable-next-line
    name: 'modal',
    methods: {
        toggleModal(target) {
            if (target.classList.contains('modal-container') || target.classList.contains('plus')) {
                this.$store.commit('toggleModal', {
                    modalOpen: !this.modalOpen
                })
            }
        }
    },
    computed: {
        modalOpen() {
            return this.$store.state.modalOpen
        }
    },
    watch: {
        modalOpen(newValue) {
            if (newValue) {
                fadeIn(this.$refs.modal, 0.1, 0.5)
            }
        }
    }
}

</script>

<style lang="scss" scoped>

@import '@/assets/scss/mixins.scss';
@import '@/assets/scss/functions.scss';

.modal-container {
    position: absolute;
    width: 100%;
    height: calc(100%);
    background-color: RGBA(17, 17, 17, 0.75);
    z-index: 10;
    display: none;
    justify-content: center;
    align-items: center;

    &.active {
        display: flex;
    }

    .modal {
        position: absolute;
        border-radius: 15px;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 40px;
        text-align: center;
        width: 70%;
        height: auto;

        @include respond-to(small-and-up) {
            min-height: 400px;
        }

        .btn-primary {
            width: 352px;
            margin: 0 auto;
            margin-left: auto;
            margin-right: auto;
            width: 260px;

            @include respond-to(small-and-up) {

                width: 352px;
            }
        }

        .form-container {
            
            input {
                margin: 0 auto;
                margin-bottom: 20px;
                width: 352px;
            }
        }

        @include respond-to(wide-and-up) {
            width: 783px;
            height: 515px;
        }

        .plus {
            position: absolute;
            width: 30px;
            height: 30px;
            display: inline-block;
            top: 0;
            right: 0;
            margin-top: 15px;
            margin-right: 20px;
            background: rgba(249, 249, 249, 0.78);
            backdrop-filter: blur(27.1828px);
            border-radius: 50%;

            &:before,
            &:after {
                content: "";
                position: absolute;
                background: rgba(60, 60, 67, 0.6);
            }

            &:before {
                top: 5px;
                bottom: 5px;
                width: 8%;
                transform: rotate(42deg);
                left: 45%;
                border-radius: 3px;
            }

            &:after {
                left: 5px;
                right: 5px;
                height: 8%;
                transform: rotate(-135deg);
                top: 47%;
                border-radius: 3px;
            }

            &:hover {
                cursor: pointer;
            }
        }

        .benefit-icon-container {
            width: 99px;
            height: 99px;
            background-image: url("@/assets/images/svg/bg-modal-icon.svg");
            background-size: cover;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 23px;
        }

        .benefit-content {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            max-width: 357px;
            text-align: center;

            h5 {
                margin-bottom: 8px;
            }

            p {
                font-size: rem(16);
            }

            ul {
                padding: 0;
                display: flex;

                li {
                    font-size: rem(13);
                }

                li:not(:nth-child(1)) {
                    margin-left: 20px;
                }
            }
        }
    }
}

</style>