import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";
import Personalize from "@/views/Personalize.vue";
import LoadingScreen1 from "@/views/LoadingScreen1.vue";
import LoadingScreen2 from "@/views/LoadingScreen2.vue";
import Question1 from "@/views/Question1.vue";
import Question2 from "@/views/Question2.vue";
import Question3 from "@/views/Question3.vue";
import Question4 from "@/views/Question4.vue";
import Question5 from "@/views/Question5.vue";
import Question6 from "@/views/Question6.vue";
import Results from "@/views/Results.vue";
import NotFound from "@/views/NotFound.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/personalize",
    name: "personalize",
    component: Personalize,
  },
  {
    path: "/loading-screen-1",
    name: "loading-screen-1",
    component: LoadingScreen1,
  },
  {
    path: "/question-1",
    name: "question-1",
    component: Question1,
  },
  {
    path: "/question-2",
    name: "question-2",
    component: Question2,
  },
  {
    path: "/question-3",
    name: "question-3",
    component: Question3,
  },
  {
    path: "/question-4",
    name: "question-4",
    component: Question4,
  },
  {
    path: "/question-5",
    name: "question-5",
    component: Question5,
  },

  {
    path: "/question-6",
    name: "question-6",
    component: Question6,
  },

  {
    path: "/loading-screen-2",
    name: "loading-screen-2",
    component: LoadingScreen2,
  },
  {
    path: "/results",
    name: "results",
    component: Results,
  },
  { path: "/404", component: NotFound },
  { path: "*", redirect: "/404" },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
