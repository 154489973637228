<template>
    <div>
        <iframe name="dummyframe" id="dummyframe" style="display: none;"></iframe>
        <form
            id="global-form"
            action="https://go.plansource.com/l/153931/2022-11-18/49mfb9"
            method="post"
            target="dummyframe"
        >
            <div style="position:absolute; left:-9999px; top: -9999px;">
                <label for="pardot_extra_field">Comments</label>
                <input type="text" id="pardot_extra_field" name="pardot_extra_field">
            </div>
            <input
                v-if="firstName"
                type="hidden"
                id="first-name"
                name="first-name"
                :value="firstName"
            >
            <input
                v-if="lastName"
                type="hidden"
                id="last-name"
                name="last-name"
                :value="lastName"
            >
            <input
                v-if="workEmail"
                type="hidden"
                id="work-email"
                name="work-email"
                :value="workEmail"
            >
            <input
                v-if="companyName"
                type="hidden"
                id="company-name"
                name="company-name"
                :value="companyName"
            >
            <input
                v-if="wellnessBudgetEst"
                type="hidden"
                id="wellness-budget-est"
                name="wellness-budget-est"
                :value="wellnessBudgetEst"
            >
            <input
                v-if="benefitsBeyond"
                type="hidden"
                id="benefits-beyond"
                name="benefits-beyond"
                :value="benefitsBeyond"
            >
            <input
                v-if="fiveBenefits"
                type="hidden"
                id="five-benefits"
                name="five-benefits"
                :value="fiveBenefits"
            >
        
            <input
                v-if="urlResults"
                type="hidden"
                id="url-results"
                name="url-results"
                :value="urlResults"
            >
        </form>
    </div>
</template>

<script>

export default {
    name: 'global-form',
    computed: {
        firstName() {return this.$store.state.globalFormData.personalizeForm.firstName},
        lastName() {return this.$store.state.globalFormData.personalizeForm.lastName},
        workEmail() {return this.$store.state.globalFormData.personalizeForm.workEmail},
        companyName() {return this.$store.state.globalFormData.personalizeForm.companyName},
        wellnessBudgetEst() {return this.$store.state.globalFormData.question2Form.selectionEstimate},
        benefitsBeyond() {return this.$store.state.globalFormData.question4Form.selections},
        fiveBenefits() {return this.$store.state.globalFormData.question5Form.selections},
        urlResults() {return this.$store.state.globalUrl}
    }
}

</script>