<template>
    <header>
        <nav :class="`navbar ${menuOpen ? 'active' : ''}`">
            <a 
                class="primary-logo" 
                href="/" 
                title="Plansource website"
            >
                <img src="@/assets/images/primary-logo.png" alt="Plansource employer questionnaire logo" />
            </a>
            <ul>
                <li>
                    <a 
                        href="https://plansource.com/company/about/" 
                        title="About plansource" 
                        target="_blank" 
                        rel="noopener nofollow"
                    >
                        about plansource
                    </a>
                </li>
                <li>
                    <a 
                        href="https://plansource.com/partners/" 
                        title="Explore partner programs" 
                        target="_blank" 
                        rel="noopener nofollow"
                    >
                        our partners
                    </a>
                </li>
            </ul>
            <hamburger-icon :hamburgerClick="hamburgerClick" />
        </nav>
    </header>
</template>

<script>

// Components
import HamburgerIcon from './HamburgerIcon.vue'

export default {
    name: 'navigation-bar',
    components: {
        HamburgerIcon
    },
    data() {
        return {
            menuOpen: false,
        };
    },
    methods: {
        hamburgerClick () {
            this.menuOpen = !this.menuOpen
            document.body.classList.toggle('body-noscroll')
        },
        resetNav() {
            if (window.innerWidth >= 767) {
                this.menuOpen = false
                document.body.classList.remove('body-noscroll')
            }
        }
    },
    created() {
        window.addEventListener('resize', this.resetNav)
    },
    destroyed() {
        window.removeEventListener('resize', this.resetNav)
    }
}
</script>

<style lang="scss" scoped>

@import '@/assets/scss/mixins.scss';
  
header {
    background-color: var(--peq-white);
    position: relative;
    height: 70px;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    margin-right: auto;
    justify-content: space-between;
    border-bottom: solid 1px var(--peq-black);
    z-index: 3;

    .navbar {
        width: 100%;
        height: 100%;
        display: inline-flex;
        align-items: center;
        margin-right: auto;
        justify-content: space-between;

        ul {
            display: none;
            margin: 0;
            align-items: center;
            height: 100%;
            padding: 0;

            @include respond-to(medium-and-up) {
                display: inline-flex;
            }

            li {
                margin-right: 80px;
                padding-left: 60px;
                height: 100%;
                display: inline-flex;
                align-items: center;
                border-left: solid black 1px;
            }
        }

        &.active {

            ul {
                position: absolute;
                display: flex;
                flex-direction: column;
                top: 70px;
                background-color: var(--peq-blue);
                width: 100%;
                height: 100vh;
                justify-content: center;

                li {
                    border: none;
                    height: auto;
                    margin-bottom: 30px;

                    a {
                        color: var(--peq-white)
                    }
                }
            }
        }
    }
}

</style>