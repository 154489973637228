<template>
    <section>
        <div class="hero bg-quaternary">
            <div class="hero-container">
                <div class="container heading-container">
                    <div class="hero-content" ref="heroContent">
                        <h3 class="heading">Thank you, {{firstName}}! 
                            Here are your results.
                        </h3>
                        <p class="subheading">{{subheading1}}</p>
                        <div class="btn-container">
                            <a href="#recommended-partners-container" class="btn-primary button">View Recommendations</a>
                            <a class="btn-secondary button" ref="pdfButton" @click="triggerPdf()">Download PDF</a>
                            <img class="loading" src="@/assets/video/loading.gif" ref="loading" alt="PDF Loading gif" />
                        </div>
                    </div>
                    <div class="video-container" ref="video1">
                        <div class="video">
                            <video 
                                disableRemotePlayback
                                playsinline
                                muted
                                loop
                                autoplay
                            >
                                <source src="@/assets/video/homepage.mp4" type="video/mp4">
                            </video>
                        </div>
                    </div>
                </div>
                <img
                    class="video-img-1"
                    src="@/assets/images/results-page-img-1.png"
                    alt="Header image 1"
                    ref="img1"
                />
            </div>
        </div>
        <div class="content container" ref="content">
            <img
                class="video-img-3"
                src="@/assets/images/results-page-img-3.png"
                alt="Header image 2"
                ref="img2"
            />
            <h3 class="heading">Your Summary</h3>
            <div class="summary-container">
                <div>
                    <h4>{{heading1}}</h4>
                    <p>{{cardSubheading1}}</p>
                    <ul class="benefit-cards-container">
                        <li v-for="benefit in queryBenefits" :key="benefit.benefit">
                            <BenefitCardPrimary :icon="benefit.icon" :benefit="benefit.benefit" />
                        </li>
                    </ul>
                </div>
                <div>
                    <div class="budget-container">
                        <div>
                            <h4>{{heading2}}</h4>
                            <h4>{{queryBudget ? '$' + queryBudget : 'TBD' }}</h4>
                            <p v-if="queryBudget">Spend per employee</p>
                        </div>
                        <img src="@/assets/images/icons/budget-icon.svg" class="budget-icon" alt="Budget icon" />
                    </div>
                    <div ref="pie">
                        <h4>{{heading3}}</h4>
                        <p>{{cardSubheading2}}</p>
                        <BudgetChart :queryDemographics="queryDemographics" />
                    </div>
                </div>
            </div>
            <div class="recommended-partners-container" id="recommended-partners-container">
                <h3 class="heading">Your Recommended Partners</h3>
                <p class="text-center subheading-2">{{subheading2}}</p>
                <ul class="recommended-partners">
                    <li class="benefit-container" v-for="(benefit, index) in queryBenefits" :key="benefit.benefit">
                        <div class="flex-group">
                            <h4>{{benefit.benefit}}</h4>
                            <div class="icon">
                                <img :src="getImagePath(benefit.icon, 'icons')" alt="Benefit icon" />
                            </div>
                        </div>
                        <RecommendedPartners 
                            :recommended-partners="recommendedPartners[index][benefit.benefit]"
                            :pdf="pdf"
                        />
                    </li>
                </ul>
            </div>
        </div>
        <ResultsFooter />
    </section>
</template>

<script>

// Helpers
import helpers from '@/helpers/helpers'

// Components
import BenefitCardPrimary from '@/components/BenefitCardPrimary.vue'
import RecommendedPartners from '@/components/RecommendedPartners.vue'
import BudgetChart from '@/components/BudgetChart.vue'
import ResultsFooter from '@/components/ResultsFooter.vue'

// Modules
import { jsPDF } from 'jspdf'
import html2canvas from 'html2canvas'

// Animations
import { fadeIn } from '@/helpers/animations'

// Data
import results from '@/data/site-copy'
import summaryCards from '@/data/site-copy'
import benefits from '@/data/benefits'
import partners from '@/data/partners'
const { subheading1, subheading2 } = results.results;
const { 
    heading1, 
    heading2, 
    heading3, 
    cardSubheading1, 
    cardSubheading2 
} = summaryCards.summaryCards

export default {
    // eslint-disable-next-line
    name: 'results',
    components: { 
        BenefitCardPrimary, 
        RecommendedPartners,
        ResultsFooter,
        BudgetChart
    },
    data() {
        return {
            recommendedPartners: null,
            getImagePath: helpers.getImagePath,
            pdf: null
        }
    },
    computed: {
        firstName() { return this.$route.query.firstName },
        subheading1() { return subheading1 },
        subheading2() { return subheading2 },
        heading1() { return heading1 },
        heading2() { return heading2 },
        heading3() { return heading3 },
        cardSubheading1() { return cardSubheading1 },
        cardSubheading2() { return cardSubheading2 },
        demographicValue() {
            const params = [
                this.$route.query.genZDemo,
                this.$route.query.millennialDemo,
                this.$route.query.genXDemo,
                this.$route.query.boomerDemo
            ]
            let total = 0
            params.forEach(amount => {
                switch(amount) {
                    case 'A little':
                        total = total + 0.3
                        break;
                    case 'A bit':
                        total = total + 0.6
                        break;
                    case 'A lot':
                        total = total + 1
                        break;
                }
            })
            return total
        },
        queryBenefits() {
            const queryBenefits = []
            benefits.benefits.forEach(benefit => {
                if (
                    benefit.benefit === this.$route.query.benefit1 ||
                    benefit.benefit === this.$route.query.benefit2 ||
                    benefit.benefit === this.$route.query.benefit3 ||
                    benefit.benefit === this.$route.query.benefit4 ||
                    benefit.benefit === this.$route.query.benefit5
                ) {
                    queryBenefits.push(benefit)
                }
            })
            return queryBenefits
        },
        queryBudget() {
            return this.$route.query.estimatedBudget
        },
        queryDemographics() {
            const queryDemographics = [
                {
                    label: 'Gen Z', 
                    value: this.getDemographicPercentage(this.$route.query.genZDemo), 
                    color: '#16A4DE' 
                },
                {
                    label: 'Millennials', 
                    value: this.getDemographicPercentage(this.$route.query.millennialDemo), 
                    color: '#666666' 
                },
                {
                    label: 'Gen X', 
                    value: this.getDemographicPercentage(this.$route.query.genXDemo), 
                    color: '#00A388' 
                },
                {
                    label: 'Baby Boomers', 
                    value: this.getDemographicPercentage(this.$route.query.boomerDemo),
                    color: '#1E2B48' 
                }
            ]
            return queryDemographics
        }
    },
    methods: {
        setPdf(value) {
            this.pdf = value
        },
        triggerPdf() {
            const content = this.$refs.content
            const image = this.$refs.img2
            const pie = this.$refs.pie
            const button = this.$refs.pdfButton
            const loading = this.$refs.loading
            image.style.display = 'none'
            pie.classList.add('pdf-active')
            button.style.display = 'none'
            loading.style.display = 'block'

            if (window.innerWidth < 767) {
                this.setPdf(true)
                setTimeout(() => {
                    this.pdfCanvas(content, this.setPdf, image, pie, button, loading)
                }, 1)
            } else {
                this.pdfCanvas(content, this.setPdf, image, pie, button, loading)
            }
        },
        pdfCanvas(content, setPdf, image, pie, button, loading) {

            html2canvas(content).then((canvas) => {
                const imgData = canvas.toDataURL('image/png')
                const imgWidth = 180
                const pageHeight = 295
                const imgHeight = canvas.height * imgWidth / canvas.width
                const doc = new jsPDF('p', 'mm')

                let heightLeft = imgHeight
                let position = 20

                doc.addImage(imgData, 'PNG', 15, position, imgWidth, imgHeight)
                heightLeft -= pageHeight

                while (heightLeft >= 0) {
                    position = heightLeft - imgHeight
                    doc.addPage()
                    doc.addImage(imgData, 'PNG', 15, position, imgWidth, imgHeight)
                    heightLeft -= pageHeight
                }
                doc.save(
                    'questionnaire-results.pdf', 
                    {returnPromise:true}
                ).then(() => {
                    button.style.display = 'flex'
                    loading.style.display = 'none'
                })
                if (setPdf) { setPdf(false) }
                image.style.display = 'block'
                pie.classList.remove('pdf-active')
            })
        },
        constructRecommendedPartners() {
            const recommendedPartners = []
            let i

            this.queryBenefits.forEach((benefit) => {
                recommendedPartners.push({
                    [benefit.benefit]: [] 
                })
            })

            for (i = 0; i < this.queryBenefits.length; ++i) {
                partners.partners.forEach(partner => {
                    partner.benefits.forEach(currentBenefit => {
                        if (currentBenefit === this.queryBenefits[i].benefit) {
                            recommendedPartners[i][currentBenefit].push(partner)
                        }
                    })
                })
            }
            this.recommendedPartners = recommendedPartners
        },
        getDemographicPercentage(amount) {
            let percentage = 0
            switch(amount) {
                case 'A little':
                    percentage = (0.3 / this.demographicValue) * 100
                    break;
                case 'A bit':
                    percentage = (0.6 / this.demographicValue) * 100
                    break;
                case 'A lot':
                    percentage = (1 / this.demographicValue) * 100
                    break;
            }
            return percentage
        },
        updateScreenWidth() {
            this.screenWidth = window.innerWidth
        }
    },
    created() {
        
        this.constructRecommendedPartners()
        helpers.adjustHeight(false)
    },
    mounted() {
        fadeIn(this.$refs.heroContent, 0.3, 0.7)
        fadeIn(this.$refs.video1, 0.5, 0.7)
        fadeIn(this.$refs.img1, 0.9, 0.7)
        fadeIn(this.$refs.img2, 1.1, 0.7)
    }
}
</script>

<style lang="scss" scoped>

@import '@/assets/scss/mixins.scss';
@import '@/assets/scss/functions.scss';

section {
    display: block;
    overflow-x: hidden;

    .loading {
        width: 30px;
        height: 30px;
        margin: 0 auto;
        display: none;
    }

    .hero {
        width: 100%;
        height: calc(100vh - 70px);
        display: flex;
        align-items: center;
        position: relative;

        @include respond-to(desktop-wide-and-up) {
            justify-content: center;
        }

        .button {
            min-width: 200px;

            @include respond-to(small-and-up) {
                min-width: 270px;
            }

            @include respond-to(wide-and-up) {
                min-width: 323px;
            }
        }

        .hero-container {
            width: 100%;
            justify-content: center;
            display: flex;
            height: 100%;
            align-items: center;
            position: relative;

            .heading-container {
                margin-top: 100px;
                margin-right: auto;

                @include respond-to(medium-and-up) {
                    margin-top: 0;
                    margin-left: 165px;
                    margin-right: auto;
                }
            }

            @include respond-to(dektop-semi-wide-and-up) {
                max-width: 1103px;
            }
        }

        .video-img-1,
        .video-img-3 {
            position: absolute;
            z-index: 1;
        }

        .video-img-1 {
            top: 0;
            right: 0;
            width: 142px;
            height: 142px;
            display: none;

            @include respond-to(medium-and-up) {
                top: -28px;
                display: block;
            }

            @include respond-to(wide-and-up) {
                top: -60px;
                width: 323px;
                height: 323px;
            }

            @media only screen and (min-width: 1800px) and (max-height: 950px) {
                display: none;
            }
        }

        .container {
            display: flex;
            flex-direction: column-reverse;
            margin-top: auto;
            margin-bottom: 40px;

            @include respond-to(medium-and-up) {
                flex-direction: row;
                margin-top: unset;
            }

            @include respond-to(desktop-wide-and-up) {
                margin: 0;
                width: 100%;
            }

            .video-container {
                width: 150px;
                height: 150px;
                position: absolute;
                display: flex;
                justify-content: center;
                top: -23px;
                left: -20px;

                @include respond-to(tiny-and-up) {
                    width: 225px;
                    height: 225px;
                }

                @include respond-to(very-small-and-up) {
                    width: 250px;
                    height: 250px;
                }

                @include respond-to(medium-and-up) {
                    width: 323px;
                    height: 323px;
                    position: relative;
                    left: unset;
                    top: unset;
                }

                img {
                    position: absolute;
                }

                .video {
                    width: 150px;
                    height: 150px;
                    position: relative;
                    overflow: hidden;

                    @include respond-to(tiny-and-up) {
                        width: 225px;
                        height: 225px;
                    }

                    @include respond-to(very-small-and-up) {
                        width: 250px;
                        height: 250px;
                    }

                    @include respond-to(medium-and-up) {
                        width: 323px;
                        height: 323px;
                    }

                    video {
                        position: absolute;
                        object-fit: cover;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                    }
                }
            }

            .hero-content {

                @include respond-to(wide-and-up) {
                    margin-right: 100px;
                }

                .heading {
                    text-align: left;
                    max-width: 265px;
                    margin-bottom: 21px;

                    @include respond-to(medium-and-up) {
                        max-width: 350px;
                    }
                }

                .subheading {
                    max-width: 300px;
                    margin-bottom: 48px;

                    @include respond-to(medium-and-up) {
                        max-width: 450px;
                    }
                }

                .btn-container {
                    display: inline-flex;
                    flex-direction: column;

                    .btn-primary {
                        margin-bottom: 17px;
                    }
                }
            }
        }
    }

    .content {
        z-index: 2;
        position: relative;

        .video-img-3 {
            right: -130px;
            top: 0;
            width: 184px;
            height: 184px;
            position: absolute;
            z-index: -1;

            @include respond-to(medium-and-up) {
                width: 387px;
                height: 387px;
                right: -275px;
                top: -128px;
            }
        }

        h3 {
            margin: 0 auto;
        }

        .heading {
            margin-bottom: 20px;
        }

        .budget-container {
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            align-items: center;

            @include respond-to(medium-and-up) {
                flex-direction: column;
            }

            @include respond-to(wide-and-up) {
                flex-direction: row;
            }

            img {
                filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                width: 69px;
                height: 69px;
            }
        }

        .summary-container {
            display: grid;
            margin-bottom: 63px;
            grid-template-columns: 1;

            @include respond-to(medium-and-up) {
                grid-template-columns: repeat(7, 1fr);
                gap: 20px;
                grid-auto-rows: minmax(100px, auto);
            }

            > div:nth-child(1),
            > div:nth-child(2) {
                
                > div {
                    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
                    border-radius: 15px;
                }
            }

            > div {
                background-color: var(--peq-white);
                border: 1px solid var(--peq-light-grey);
                border-radius: 10px;
                padding: 30px 28px;

                @include respond-to(medium-and-up) {
                    padding: 29px 53px;
                }
            }

            > div:nth-child(1) {
                background-image: url("@/assets/images/bg/bg-summary-card.png");
                background-size: cover;
                grid-column: 1;
                grid-row: 1;
                margin-bottom: 20px;

                @include respond-to(medium-and-up) {
                    grid-column: 1 / 5;
                    margin-bottom: 0;
                }

                > h4,
                > p {
                    color: var(--peq-white);
                }

                > h4 {
                    margin-bottom: 15px;
                }

                > p {
                    max-width: 325px;
                    margin-bottom: 29px;
                }
            }

            > div:nth-child(2) {
                grid-column: 1;
                grid-row: 2;

                @include respond-to(medium-and-up) {
                    grid-column: 5 / 8;
                    grid-row: 1;
                }

                > div {
                    padding: 24px 35px;
                }
            
                > div:nth-child(1) {
                    margin-bottom: 22px;

                    h4:nth-child(1) {
                        margin-bottom: 0;
                    }

                    h4:nth-child(2) {
                        color:  var(--peq-green);
                        margin-bottom: 0;
                    }
                }

                > div:nth-child(2) {

                    h4:nth-child(1) {
                        margin-bottom: 12px;
                    }
                }
            }
        }

        .subheading-2 {
            max-width: 600px;
            margin: 0 auto;
            margin-bottom: 60px;
        }

        .benefit-cards-container {
            display: flex;
            flex-wrap: wrap;
            padding: 0;
            justify-content: center;

            @include respond-to(medium-and-up) {
                justify-content: flex-start;
            }
        }

        .recommended-partners-container {
            padding-top: 20px;
        }

        .recommended-partners {
            padding: 0;

            .benefit-container {
                margin-top: 24px;
            }

            h4 {
                margin: 0 20px 25px 0;
            }

            .icon {
                background-color: var(--peq-white);
                border-radius: 50%;
                width: 60px;
                height: 60px;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    width: 26px;
                    height: 26px;
                }
            }
        }
    }
}

</style>