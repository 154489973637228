<template>
    <div
        @click="openSelections();"
        :class="`benefit-selections
        ${selectionsOpen ? 'active' : ''}`"
        ref="benefitSelections"
        id="benefit-selections"
    >
        <div class="open-container">
            <p class="your-selects">[Your selects - tap to remove]</p>
            <ul class="selected-cards secondary">
                <li v-for="selection in selections" :key="selection">
                    <div
                        class="selected-card secondary active"
                        @click="updateSelections(selection, 'remove');"
                    >
                        <p>{{selection}}</p>
                        <div class="select-icon"></div>
                    </div>
                </li>
            </ul>
            <button @click.stop="closeSelections();" class="btn-primary">Confirm</button>
        </div>
        <p class="open-text">tap to view selections <span>{{selections.length}}</span> / 5</p>
    </div>
</template>

<script>

// Animations
import { fadeIn } from '@/helpers/animations'

export default {
    name: 'benefit-selections',
    props: {
        selections: { type: Array, default() { return [] } },
        updateSelections: { type: Function, default: () => {} }
    },
    data() {
        return {
            selectionsOpen: false
        }
    },
    methods: {
        openSelections() {
            this.selectionsOpen = true
        },
        closeSelections() {
            this.selectionsOpen = false
        }
    },
    mounted() {
        fadeIn(this.$refs.benefitSelections, 0.3, 0.7)
    }
}

</script>

<style lang="scss" scoped>

@import '@/assets/scss/mixins.scss';
@import '@/assets/scss/functions.scss';

.benefit-selections {
    position: absolute;
    width: 100%;
    height: 32px;
    background: var(--peq-navy);
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    &.active {
        height: auto;
        z-index: 10;
        background-color: var(--peq-off-white);
        box-shadow: 0px -94px 80px rgba(0, 0, 0, 0.06), 0px -39.271px 33.4221px rgba(0, 0, 0, 0.0431313), 0px -20.9961px 17.869px rgba(0, 0, 0, 0.0357664), 0px -11.7703px 10.0172px rgba(0, 0, 0, 0.03), 0px -6.2511px 5.32008px rgba(0, 0, 0, 0.0242336), 0px -2.60122px 2.21381px rgba(0, 0, 0, 0.0168687);

        &:hover {
            cursor: unset;
        }
        .open-text {
            display: none;
        }

        .open-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 15px 25px;

            @include respond-to(medium-and-up) {
                padding: 40px 25px;
            }

            .your-selects {
                color: var(--peq-black);
            }

            .selected-cards {
                display: flex;
                flex-direction: column;
                margin-bottom: 0;
                justify-content: center;
                padding: 0;

                @include respond-to(medium-and-up) {
                    margin-bottom: 30px;
                }

                .selected-card {
                    background: var(--peq-navy);
                    margin-right: 6px;
                    display: flex;
                    align-items: center;
                    margin-bottom: 27px;
                    width: 352px;
                    height: 51px;
                    border-radius: 200px;
                    flex-direction: row-reverse;
                    justify-content: flex-end;
                    color: var(--peq-white);
                    font-weight: 600;

                    &:hover {
                        cursor: pointer;
                    }

                    p {
                        margin-bottom: 0;
                    }

                    .select-icon {
                        margin-left: 15px;
                        margin-right: 17px;
                        width: 23px;
                        height: 23px;
                        background-color: var(--peq-white);
                        border: 1px solid var(--peq-white);
                        border-radius: 50%;
                        position: relative;
                        display: inline-block;

                        &:before {
                            position: absolute;
                            left: 6px;
                            top: 15%;
                            height: 60%;
                            width: 2px;
                            background-color: var(--peq-navy);
                            content: "";
                            transform: translateX(10px) rotate(-45deg);
                            transform-origin: left bottom;
                        }

                        &:after {
                            position: absolute;
                            left: -3px;
                            bottom: 6px;
                            height: 2px;
                            width: 60%;
                            background-color: var(--peq-navy);
                            content: "";
                            transform: translateX(10px) rotate(-45deg);
                            transform-origin: left bottom;
                        }
                    }
                }
            }
        }
    }

    .open-container {
        display: none;
    }

    span {
        margin-left: 5px;
    }

    @include respond-to(medium-and-up) {
        height: 40px; 
    }
    
    &:hover {
        cursor: pointer;
    }

    .open-text {
        color: var(--peq-white);
        margin: 0;
        text-transform: uppercase;
        font-size: rem(12);
        font-weight: 600;

        &:hover {
            cursor: pointer;
        }
    }
}

</style>