export default {
    homeCopy: {
      heading: 'benefit assessment tool',
      subheading: 'Your company is unique. Your benefits offering should be too.',
      copy: 'Got a few minutes? Complete our quick assessment and receive a recommendation of best fit benefits and providers for your organization and employees.'
    },
    personalizeCopy: {
      heading: 'But first, let\'s get to know each other.',
      subheading: 'We\'ll use this to personalize your experience.',
      disclaimer: "PlanSource may share your information with your benefit broker and the partners recommended to you in our PlanSource Partner Marketplace."
    },
    loadingScreen1: {
      subheading: 'Your customized HR plan begins here. Let\'s work together to determine the right solution for you.'
    },
    loadingScreen2: {
      subheading: 'Sit tight! Not long until you\'ll have your best fit solutions for your organization.'
    },
    question1: {
      heading: 'How would you describe your interest in enhancing your total wellness benefits for employees?',
      sliderPoints: ['none', 'minimal', 'moderate', 'high'],
      icon: 'total-wellness-icon',
      tooltip: 'Total Wellness',
      tooltipCopy: 'Total wellness encompasses financial, social, health and mental wellbeing.'
    },
    question2: {
      heading: 'Do you have a set budget for your investment in total employee wellness?',
      sliderPoints: ['$6,000', '$8,000', '$10,000', '$12,000'],
      modalHeading: 'Set your est. budget below',
      modalSubHeading: 'Spend Per Employee',
      tooltip: 'budget',
      questions: [
        {
          icon: 'tick-icon',
          copy: 'Yes we do',
          opensModal: true
        },
        {
          icon: 'cross-icon',
          copy: 'No we don\'t'
        },
        {
          icon: 'question-icon',
          copy: 'Still planning'
        }
      ]
    },
    question3: {
      heading: 'How much of your workforce is in the following demographics?',
      questions: [
        {
          generation: 'Gen Z',
          year: '1997 - 2012',
          sliderPoints: ['A little', 'A bit', 'A lot'],
          formItem: 'genZDemo'
        },
        {
          generation: 'Millennial',
          year: '1981 - 1996',
          sliderPoints: ['A little', 'A bit', 'A lot'],
          formItem: 'millennialDemo'
        },
        {
          generation: 'Gen X',
          year: '1965 - 1980',
          sliderPoints: ['A little', 'A bit', 'A lot'],
          formItem: 'genXDemo'
        },
        {
          generation: 'Baby Boomers',
          year: '1946 - 1964',
          sliderPoints: ['A little', 'A bit', 'A lot'],
          formItem: 'boomerDemo'
        }
      ]
    },
    question4: {
      heading: 'Beyond core HR benefits, what benefits are you currently investing in?',
      tooltip: 'core HR benefits,',
      questions: [
        {
          copy: 'Critical illness'
        },
        {
          copy: 'Hospital indemnity',
        },
        {
          copy: 'Accident insurance'
        },
        {
          copy: 'Student loan programs'
        },
        {
          copy: 'Increased or unlimited PTO'
        },
        {
          copy: 'Pet Insurance',
        },
        {
          copy: 'Other'
        },
        {
          copy: 'None of the above'
        },
      ]
    },
    question5: {
      heading: 'Choose the top 5 benefits you would be interested in offering to your employees.'
    },
 
    question6: {
      heading: 'Select your biggest challenge when navigating the benefits ecosystem?',
      questions: [
        {
          icon: 'stopwatch-icon',
          copy: 'Limited resources to research and vet providers'
        },
        {
          icon: 'wrench-icon',
          copy: 'Benefit broker does not provide this service.',
        },
        {
          icon: 'mind-icon',
          copy: 'Overwhelmed with options'
        },
        {
          icon: 'question-icon',
          copy: 'Not sure where to begin'
        }
      ]
    },
  
    results: {
      subheading1: 'Click below to view our recommendations based on your survey answers. Feel free to download the results and share them with your team and your benefit broker.',
      subheading2: 'Based on your answers to our survey, here are the recommended partners we have chosen for you. We recommend that you download a PDF of your results from above and share it with your team and your benefit broker.',
      footerHeading: 'We\'ll be in touch!',
      footerCopy: 'Thank you so much for your time! We will reach out by email within the next 24 hours to setup a meeting to discuss. In the meantime, you can explore our partner marketplace.',
      buttonCopy: 'Explore Partner Marketplace'
    },
    summaryCards: {
      heading1: 'Your Benefits Plan',
      cardSubheading1: 'These were identified as the top benefits of interest for your company.',
      cardSubheading2: 'An estimated snapshot of the demographics of your company.',
      heading2: 'Your Budget',
      heading3: 'Your People'
    }
}