<template>
    <div class="budget-chart">
        <vc-donut
            :sections="sections"
            :thickness="17"
            :size="162"
            background="white"
            foreground="white"
        ></vc-donut>
        <ul class="percentages">
            <li
                class="percentage"
                v-for="(percentage, index) in sections"
                :key="index"
            >
                {{Math.round(percentage.value) + ' %'}}
            </li>
        </ul>
        <div class="budget-keys">
            <p class="gen-z">Gen Z</p>
            <p class="millennials">Millennials</p>
            <p class="gen-x">Gen X</p>
            <p class="baby-boomers">Baby Boomers</p>
        </div>
    </div>
</template>

<script>

export default {
    name: 'budget-chart',
    props: {
        queryDemographics: { type: Array, default() { return [] } }
    },
    data() {
        return {
            sections: this.queryDemographics
        }
    }
}

</script>

<style lang="scss" scoped>

@import '@/assets/scss/mixins.scss';
@import '@/assets/scss/functions.scss';

.budget-chart {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    @include respond-to(very-small-and-up) {
        flex-direction: row;
    }

    @include respond-to(medium-and-up) {
        flex-direction: column;
    }

    @include respond-to(wide-and-up) {
        flex-direction: row;

        .cdc-container {
            margin-bottom: 0;
        }
    }

    .cdc-container {
        margin-bottom: 20px;

        @include respond-to(very-small-and-up) {
            margin-right: 10px;
        }

        @include respond-to(medium-and-up) {
            margin-right: 0;
        }

        @include respond-to(wide-and-up) {
            margin-bottom: 0;
            margin-right: 10px;
        }
    }

    p {
        font-size: rem(10);

        &:before {
            content: '';
            width: 10px;
            height: 10px;
            display: inline-block;
            border-radius: 10px;
            margin-right: 8px;
        }
    }

    .gen-z:before {
        background-color: var(--peq-bright-blue);
    }

    .millennials:before {
        background-color: var(--peq-light-off-black);
    }

    .gen-x:before {
        background-color: var(--peq-green);
    }

    .baby-boomers:before {
        background-color: var(--peq-navy);
    }

    .percentages {
        margin: 0;
        padding: 0;
        display: none;

        li {
            margin-bottom: 10px;
        }
    }
}

.pdf-active {

    .budget-chart {
        justify-content: space-evenly;
        flex-direction: row!important;
    }
    .cdc-container {
        display: none;
    }

    .percentages {
        display: block;
    }
}

</style>    