<template>
    <section class="bg-tertiary baseline margin-auto question-5">
        <div class="container" ref="container" id="container">
            <div class="flex-group-column">
                <p class="question-number">Question 5 of 6</p>
                <h3>{{heading}}</h3>
                <filtering-navigation-bar :categories="categories" :updateFilterBy="updateFilterBy" />
                <BenefitCards 
                    :slides="slides" 
                    :filteredBenefits="filteredBenefits"
                    :updateSelections="updateSelections"
                    :disableCards="disableCards"
                    :question5-form="question5Form"
                    :update-modal-data="updateModalData"
                />
                <p
                    v-if="
                    this.$store.state.errorFields.selectionRequired &&
                    question5Form.selections.length === 0
                    " 
                    class="error-message"
                >Please make at least one selection.</p>
            </div>
        </div>
        <div class="flex-group button-group" ref="buttons" id="button">
            <Button
                btn-class="btn-secondary"
                btn-text="Back"
                :btn-route="-1"
                :disabled="false"
            />
            <Button
                btn-class="btn-primary" 
                btn-route="/question-6" 
                btn-text="Continue"
                :form-data="formData"
                form-key="question5Form"
                :local-query-params="constructedQueryParams"
                :disabled="!formHasData"
                :update-url="true"
                :animation-items="['#container', '#benefit-selections']"
            />
        </div>
        <Modal>
            <div class="benefit-icon-container">
                <img class="icon" :src="modalData.imagePath" alt="Benefit icon" />
            </div>
            <div class="benefit-content">
                <h5>{{modalData.benefit}}</h5>
                <ul>
                    <li v-for="category in modalData.categories" :key="category">
                        {{category}}
                    </li>
                </ul>
                <p>{{modalData.description}}</p>
            </div>
        </Modal>
        <BenefitSelections
            :selections="question5Form.selections"
            :update-selections="updateSelections"
        />
    </section>
</template>

<script>

// Helpers
import helpers from '@/helpers/helpers'

// Components
import question5 from '@/data/site-copy'
import Button from '@/components/Button.vue'
import FilteringNavigationBar from '@/components/FilteringNavigationBar.vue'
import BenefitCards from '@/components/BenefitCards.vue'
import Modal from '@/components/Modal.vue'
import BenefitSelections from '@/components/BenefitSelections.vue'

// Animations
import { fadeInUp } from '@/helpers/animations'

// Data
import benefits from '@/data/benefits'
const { heading } = question5.question5
const { categories } = benefits

export default {
    name: 'question-5',
    components: { 
        Button, 
        FilteringNavigationBar,
        BenefitCards,
        Modal,
        BenefitSelections
    },
    data() {
        return {
            filteredBenefits: benefits.benefits,
            filterBy: 'All',
            itemsPerSlide: 12,
            slides: helpers.constructSlides(benefits.benefits, 12),
            question5Form: {
                selections: typeof(this.$store.state.globalFormData.question5Form.selections) !== 'undefined' ?
                this.$store.state.globalFormData.question5Form.selections :
                []
            },
            disableCards: false,
            constructedQueryParams: {},
            modalData: {},
            getImagePath: helpers.getImagePath
        }
    },
    computed: {
        formHasData() { return this.question5Form.selections.length !== 0 },
        formData() { return this.formHasData ? this.question5Form : {} },
        heading() { return heading },
        categories() { return categories }
    },
    methods: {
        updateFilterBy(element) {
            this.filterBy = element
            this.filterBenefits()
            this.slides = helpers.constructSlides(this.filteredBenefits, this.itemsPerSlide)
        },
        filterBenefits() {
            let filtered = []
            benefits.benefits.forEach(element => {
                element.categories.forEach(category => {
                    if (this.filterBy === 'All') {
                        filtered = benefits.benefits
                    }
                    if (this.filterBy === category) {
                        filtered.push(element)
                        return
                    }
                })
            })
            this.filteredBenefits = filtered
        },
        updateSelections(selection, action) {
            if (action === 'update') {
                this.question5Form.selections.push(selection)
            } else {
                const benefitIndex = this.question5Form.selections.findIndex(benefit => {
                    return benefit === selection
                })
                this.question5Form.selections.splice(benefitIndex, 1)
            }
        },
        constructQueryParams() {
            const params = {}
            this.question5Form.selections.forEach((benefit, index) => {
                params[`benefit${index+1}`] = benefit
            })
            this.constructedQueryParams = params
        },
        updateModalData(selection) {
            benefits.benefits.forEach(benefit => {
                if (selection === benefit.benefit) {
                    this.modalData = benefit
                    this.modalData.imagePath = this.getImagePath(benefit.icon, 'icons')
                }
            })
        }
    }, 
    watch: {
        'question5Form.selections': function () {
            if (this.question5Form.selections.length === 5) {
                this.disableCards = true
            } else {
                this.disableCards = false
            }
            this.constructQueryParams()
        }
    },
    created() {
        helpers.adjustHeight(true)
    },
    mounted() {
        this.constructQueryParams()
        fadeInUp(this.$refs.container, 0.1, 0.7)
        fadeInUp(this.$refs.buttons, 0.1, 0.7)
    },
}
</script>

<style lang="scss" scoped>

@import '@/assets/scss/mixins.scss';
@import '@/assets/scss/functions.scss';

.baseline.question-5 .container {
    height: calc(100% - 150px);
    overflow: scroll;

    @include respond-to(wide-and-up) {
        justify-content: center;
        height: auto;
        overflow: unset;
        margin-bottom: 0;
    }
}

.baseline.question-5 > div:first-child {
    margin-top: 30px;

    @include respond-to(wide-and-up) {
        margin-top: 0;
    }
}

section {
    justify-content: inherit;

    @include respond-to(wide-and-up) {
        justify-content: center;
    }

    h3 {
        margin-bottom: 0;
        
        @include respond-to(desktop-and-up) {
            max-width: 100%;
        }
    }

    .filtering-container {
        margin-bottom: 30px;
    }

    .button-group {
        margin-bottom: 45px;

        @include respond-to(tiny-and-up) {
            margin-bottom: 60px;
        }

        @include respond-to(wide-and-down) {
            position: absolute;
            bottom: 0;
            width: 100%;
            justify-content: center;
            height: 50px;
        }

        button {
            
            @include respond-to(medium-and-up) {
                min-width: 30%;
            }
        }
    }

    .benefit-icon-container img {
        filter: invert(98%) sepia(98%) saturate(0%) hue-rotate(5deg) brightness(103%) contrast(102%);
    }
}

</style>