<template>
    <div class="hamburger-container">
        <button
            id="hamburger"
            class="hamburger"
            aria-label="Toggle Menu"
            @click="hamburgerClick"
        >
            <div class="group">
                <span />
                <span />
                <span />
            </div>
        </button>
    </div>
</template>

<script>
export default {
    name: 'hamburger-icon',
    props: {
        hamburgerClick: { type: Function, default: () => {} }
    },
}
</script>

<style lang="scss" scoped>

@import '@/assets/scss/mixins.scss';
@import '@/assets/scss/functions.scss';

.hamburger-container {
    height: 100%;
    display: flex;
    align-items: center;
    border-left: solid 1px var(--peq-black);

    @include respond-to(medium-and-up) {
        display: none;
    }

    .hamburger {
        cursor: pointer;
        outline: 0;
        border: 0;
        height: 35px;
        width: 50px;
        padding: 0;
        background-color: transparent;
        margin-right: 25px;
        padding-left: 25px;

        @include respond-to(medium-and-up) {
            margin-right: 0;
            order: 1;
            display: none;
        }

        .group {
            height: 100%;
            position: relative;
            margin: 0;

            span {
                display: block;
                position: absolute;
                height: 3px;
                width: 23px;
                background-color: var(--peq-blue);
                border-radius: 1px;
                opacity: 1;
                left: 0px;
                -webkit-transform: rotate(0deg);
                -moz-transform: rotate(0deg);
                -o-transform: rotate(0deg);
                transform: rotate(0deg);
                -webkit-transition: transform .25s ease-in-out;
                -moz-transition: transform .25s ease-in-out;
                -o-transition: transform .25s ease-in-out;
                transition: transform .25s ease-in-out;

                &:nth-child(1) {
                    top: 9px;
                }

                &:nth-child(2) {
                    top: 17px;
                }

                &:nth-child(3) {
                    bottom: 7px;
                }
            }
        }
    }
}

.navbar.active .group {

    span:nth-child(1) {
        opacity: 0
    }

    span:nth-child(3) {
        top: 17px;
        left: 0;
    }

    span:nth-child(2) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    span:nth-child(2),
    span:nth-child(3) {
        width: 25px;
    }

    span:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }
}

</style>
