var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{ref:"section",staticClass:"bg-secondary margin-auto"},[_c('div',{ref:"container",staticClass:"container flex-group-column",attrs:{"id":"container"}},[_c('h3',[_vm._v(_vm._s(_vm.heading))]),_c('p',[_vm._v(_vm._s(_vm.subheading))]),_c('div',{staticClass:"form-container"},[_c('input',{class:`${
          this.$store.state.errorFields.firstName &&
          !_vm.personalizeForm.firstName
            ? 'error-input'
            : ''
        }`,attrs:{"placeholder":"Name"},domProps:{"value":_vm.personalizeForm.firstName},on:{"input":(event) => (_vm.personalizeForm.firstName = event.target.value)}}),_c('input',{class:`${
          this.$store.state.errorFields.lastName && !_vm.personalizeForm.lastName
            ? 'error-input'
            : ''
        }`,attrs:{"placeholder":"Last Name"},domProps:{"value":_vm.personalizeForm.lastName},on:{"input":(event) => (_vm.personalizeForm.lastName = event.target.value)}}),_c('input',{class:`${
          (this.$store.state.errorFields.workEmail &&
            !_vm.personalizeForm.workEmail) ||
          (this.$store.state.errorFields.workEmailRegex &&
            _vm.personalizeForm.workEmail)
            ? 'error-input'
            : ''
        }`,attrs:{"placeholder":"E-mail"},domProps:{"value":_vm.personalizeForm.workEmail},on:{"input":(event) => (_vm.personalizeForm.workEmail = event.target.value)}}),(
          Object.keys(this.$store.state.errorFields).length !== 0 &&
          this.$store.state.errorFields.workEmailRegex
        )?_c('p',{staticClass:"error-message"},[_vm._v(" Please provide a valid business email. ")]):_vm._e(),_c('input',{class:`${
          this.$store.state.errorFields.companyName &&
          !_vm.personalizeForm.companyName
            ? 'error-input'
            : ''
        }`,attrs:{"placeholder":"Company Name"},domProps:{"value":_vm.personalizeForm.companyName},on:{"input":(event) => (_vm.personalizeForm.companyName = event.target.value)}})]),_c('div',{attrs:{"id":"button"}},[_c('Button',{attrs:{"btn-class":"btn-primary","btn-route":"/loading-screen-1","btn-text":"Continue","form-data":_vm.formData,"form-key":"personalizeForm","local-query-params":_vm.constructedQueryParams,"disabled":!_vm.formHasData,"animation-items":['#container']}})],1),_c('p',{staticClass:"disclaimer text-center"},[_vm._v(_vm._s(_vm.disclaimer))])]),_c('img',{ref:"sprinkles1",staticClass:"orange-sprinkles-left",attrs:{"src":require("@/assets/images/svg/orange-sprinkles-left.svg"),"alt":"Orange sprinkles image"}}),_c('img',{ref:"sprinkles2",staticClass:"orange-sprinkles-right",attrs:{"src":require("@/assets/images/svg/orange-sprinkles-right.svg"),"alt":"Orange sprinkles image"}})])
}
var staticRenderFns = []

export { render, staticRenderFns }