<template>
    <div 
        @click="toggleActiveCard($event.target)" 
        :class="`benefit-card ${active ? 'active': ''}`" 
        ref="benefitCard"
        translate="no"
    >
        <div class="card-container">
            <p class="benefit-name">{{benefit}}</p>
            <div class="information-icon" @click.stop="toggleModal(); updateModalData(benefit);">
                <p>i</p>
            </div>
        </div>
        <div class="card-active-container">
            <div class="information-icon" @click.stop="toggleModal(); updateModalData(benefit);">
                <p>i</p>
            </div>
            <img class="icon" :src="getImagePath(icon, 'icons')" alt="Benefit icon" />
            <p class="benefit-name">{{benefit}}</p>
        </div>
        <div class="plus radius"></div>
    </div>
</template>

<script>

// Helpers
import helpers from '@/helpers/helpers'

export default {
    name: 'benefit-card-secondary',
    props: {
        benefit: { type: String, default: '' },
        icon: { type: String, default: '' },
        updateSelections: { type: Function, default: () => {} },
        disabled: { type: Boolean, default: false },
        selections: { type: Array, default() { return [] } },
        updateModalData: { type: Function, default: () => {} },
        carouselEvent: { type: String, default: '' }
    },
    data() {
        return {
            getImagePath: helpers.getImagePath
        }
    },
    methods: {
        toggleActiveCard(selection) {
            const card = selection.classList.contains('benefit-card') ? selection : selection.closest(".benefit-card")
            if (this.carouselEvent !== 'touchEnd') {
                card.classList.toggle('active')
                this.updateSelections(
                    card.querySelector('.benefit-name').innerHTML,
                    card.classList.contains('active') ? 'update' : 'remove'
                )
            }
        },
        toggleModal() {
            if (this.carouselEvent !== 'touchEnd') {
                this.$store.commit('toggleModal', {
                    modalOpen: !this.modalOpen
                })  
            }
        },
        disableCards() {
            if (this.disabled) {
                if (!this.$refs.benefitCard.classList.contains('active')) {
                    this.$refs.benefitCard.classList.add('disabled')
                }
            } else {
                if (!this.$refs.benefitCard.classList.contains('active')) {
                    this.$refs.benefitCard.classList.remove('disabled')
                }
            }
        }
    },
    watch: {
        disabled() {
            this.disableCards()
        }
    },
    computed: {
        active() {
            let active = false
            if (this.selections && this.selections.includes(this.benefit)) {
                active = true
            }
            return active
        },
        modalOpen() {
            return this.$store.state.modalOpen
        }
    },
    mounted() {
        this.disableCards()
    }
}

</script>

<style lang="scss" scoped>

@import '@/assets/scss/mixins.scss';
@import '@/assets/scss/functions.scss';

.benefit-card {
    width: 132px;
    height: 132px;
    background: var(--peq-white);
    border-radius: 15px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &:hover {
        cursor: pointer;
    }

    .card-active-container {
        display: none;
    }

    .benefit-name {
        color: var(--peq-light-off-black);
        max-width: 90px;
        font-size: rem(12);
        line-height: 130%;
        @include line-clamp(3);
        word-break: break-word;
    }

    .information-icon {
        border: 0.1rem solid var(--peq-light-off-black);
        border-radius: 100%;
        width: 16px;
        height: 16px;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
            cursor: pointer;
        }

        p {
            margin: 0;
            color: var(--peq-light-off-black);
            font-weight: 600;
            font-size: rem(13);
        }
    }

    .card-container {
        display: flex;
        justify-content: space-between;
    }

    .plus {
        position: relative;
        width: 23px;
        min-height: 23px;
        background:var(--peq-green);
        display: inline-block;
        border-radius: 50%;

        &:before,
        &:after {
            content: "";
            position:absolute;
            background: var(--peq-white);
        }

        &:before {
            left: 50%;
            top: 5px;
            bottom: 5px;
            width: 7%;
            transform: translateX(-50%);
        }

        &:after {
            top: 50%;
            left: 5px;
            right: 5px;
            height: 7%;
            transform: translateY(-50%);
        }

        &:hover {
            cursor: pointer;
        }
    }

    &.active {
        background: var(--peq-navy);

        .card-container {
            display: none;
        }

        .card-active-container {
            display: block;

            img {
                filter: invert(98%) sepia(98%) saturate(0%) hue-rotate(5deg) brightness(103%) contrast(102%);
            }
        }

        img {
            display: block;
        }

        img,
        .benefit-name {
            margin: 0 auto;
            margin: 0 auto;
        }

        .benefit-name {
            color: var(--peq-white);
            text-align: center;
            @include line-clamp(2);
        }

        .information-icon {
            border: 0.1rem solid var(--peq-white);
            margin-left: auto;

            p {
                color: var(--peq-white);
            }
        }

        .plus {

            background:var(--peq-white);

            &:before,
            &:after {
                background: var(--peq-navy);
            }

            &:before {
                transform: rotate(42deg);
                left: 46%;
            }

            &:after {
                transform: rotate(-135deg);
                top: 47%;
            }
        }
    }

    &.disabled {
        opacity: 0.5;
        pointer-events: none;
    }
}

</style>