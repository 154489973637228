<template>
    <div class="benefit-card">
        <img class="icon" :src="getImagePath(icon, 'icons')" alt="Benefit icon" />
        <p>{{benefit}}</p>
    </div>
</template>

<script>

// Helpers
import helpers from '@/helpers/helpers'

export default {
    name: 'benefit-card-primary',
    props: { 
        icon: { type: String, default: '' },
        benefit: { type: String, default: '' }
    },
    data() {
        return {
            getImagePath: helpers.getImagePath
        }
    }
}

</script>

<style lang="scss" scoped>

.benefit-card {
    width: 159px;
    height: 143px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    margin-right: 25px;
    margin-bottom: 20px;
    background-color: var(--peq-white);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .icon {
        width: 60px;
        height: 60px;
    }

    p {
        max-width: 120px;
        text-align: center;
        margin-bottom: 0;
        word-wrap: break-word;
    }
}

</style>
