<template>
    <section class="bg-tertiary baseline margin-auto question-4">
        <div class="container flex-group-column" ref="container" id="container">
            <p class="question-number">Question 4 of 6</p>
            <h3 ref="heading">{{heading}}</h3>
            <QuestionCards
                :questions="questions"
                type="secondary"
                :updateSelection="updateSelection"
                :multi-select="true"
                :selections="question4Form.selections"
            />
        </div>
        <div class="flex-group button-group" ref="buttons" id="button">
            <Button
                btn-class="btn-secondary"
                btn-text="Back"
                :btn-route="-1"
                :disabled="false"
            />
            <Button
                btn-class="btn-primary"
                btn-route="/question-5"
                btn-text="Continue"
                :disabled="!formHasData"
                :form-data="formData"
                form-key="question4Form"
                :animation-items="['#container']"
            />
        </div>
    </section>
</template>

<script>

// Helpers
import helpers from '@/helpers/helpers'

// Components
import Button from '@/components/Button.vue'
import QuestionCards from '@/components/QuestionCards.vue'

// Animations
import { fadeInUp } from '@/helpers/animations'

// Data
import question4 from '@/data/site-copy'
const { heading, questions, tooltip } = question4.question4

export default {
    name: 'question-4',
    components: { Button, QuestionCards },
    data() {
        return {
            question4Form: {
                selections: 
                    typeof(this.$store.state.globalFormData.question4Form.selections) !== 'undefined' ?
                    this.$store.state.globalFormData.question4Form.selections :
                    []
            }
        }
    },
    computed: {
        formHasData() { return this.question4Form.selections.length !== 0 },
        formData() { return this.formHasData ? this.question4Form : {} },
        heading() { return heading},
        questions() { return questions },
        tooltip() { return tooltip }
    },
    methods: {
        updateSelection(selection) {
            const card = selection.classList.contains('question-card') ? selection : selection.parentElement
            const text = card.getElementsByTagName('p')[0].innerHTML
            if (card.classList.contains('active')) {
                this.question4Form.selections.push(text)
            } else {
                const index = this.question4Form.selections.indexOf(text)
                this.question4Form.selections.splice(index, 1)
            }
        }
    },
    created() {
        helpers.adjustHeight(true)
    },
    mounted() {
        fadeInUp(this.$refs.container, 0.1, 0.7)
        fadeInUp(this.$refs.buttons, 0.1, 0.7)
    }
}
</script>

<style lang="scss" scoped>

@import '@/assets/scss/mixins.scss';
@import '@/assets/scss/functions.scss';

section {

    h3 {
        margin-bottom: 30px;
    }
}

</style>