<template>
    <section>
        <div class="swiper benefits-swiper" ref="benefitsSwiper">
            <div class="swiper-wrapper" ref="swiperWrapper">
                <div class="swiper-slide" v-for="slide in slides" :key="slide.number">
                    <ul class="benefits-container">
                        <li v-for="benefit in slide.slide" :key="benefit.benefit">
                            <BenefitCardSecondary
                                :benefit="benefit.benefit"
                                :icon="benefit.icon"
                                :updateSelections="updateSelections"
                                :disabled="disableCards"
                                :selections="question5Form.selections"
                                :update-modal-data="updateModalData"
                                :carousel-event="carouselEvent"
                            />
                        </li>
                    </ul>
                </div>
            </div>
            <div class="swiper-scrollbar"></div>
        </div>
        <ul class="mobile-benefits-container" ref="benefitCards">
            <li v-for="benefit in filteredBenefits" :key="benefit.benefit">
                <BenefitCardSecondary 
                    :benefit="benefit.benefit" 
                    :icon="benefit.icon"
                    :updateSelections="updateSelections"
                    :disabled="disableCards"
                    :selections="question5Form.selections"
                    :update-modal-data="updateModalData"
                />
            </li>
        </ul>
    </section>
</template>

<script>

// Components
import BenefitCardSecondary from '@/components/BenefitCardSecondary.vue'

// Modules.
import Swiper, { Mousewheel, Scrollbar, FreeMode } from 'swiper'
Swiper.use([Mousewheel, Scrollbar, FreeMode])

// Helpers
import { fadeIn } from '@/helpers/animations'

// Styles
import 'swiper/swiper.min.css'
import 'swiper/css/scrollbar'
import 'swiper/scss/mousewheel'
import 'swiper/scss/free-mode'

export default {
    name: 'benefits-cards',
    components: { BenefitCardSecondary },
    props: {
        slides: { type: Array, default() { return [] } },
        filteredBenefits: { type: Array, default() { return [] } },
        updateSelections: { type: Function, default: () => {} },
        disableCards: { type: Boolean, default: false },
        question5Form: { type: Object, default() { return {} } },
        updateModalData: { type: Function, default: () => {} }
    },
    data() {
        return {
            slider: null,
            swiper: null,
            carouselEvent: null
        }
    },
    watch: {
        filteredBenefits() {
            fadeIn(this.$refs.benefitCards, 0, 0.7)
            fadeIn(this.$refs.swiperWrapper, 0, 0.7)
            this.swiper.destroy()
        },
        slides() {
            setImmediate(() => {
                this.initSwiper()
                this.swiper.update()
                this.swiper.setProgress(0, 300)
            }, 10)
        }
    },
    methods: {
        setCarouselEvent(event) {
            this.carouselEvent = event
        },
        initSwiper() {
            const setCarouselEvent = this.setCarouselEvent
            this.swiper = new Swiper(this.$refs.benefitsSwiper, {
                slideToClickedSlide: true,
                slidesPerView: 1.1,
                threshold: 10,
                loop: false,
                freeMode: {
                    enabled: true,
                    sticky: false,
                    momentumBounce: false,
                },
                grabCursor: true,
                scrollbar: {
                    el: '.swiper-scrollbar',
                    draggable: true,
                    dragSize: 100,
                    forceToAxis: true
                },
                mousewheel: {
                    enabled: true,
                },
                on: {
                    touchEnd: function () {
                        setCarouselEvent('touchEnd')
                    },
                    click: function () {
                        setCarouselEvent('click')
                    }
                },
            })
        }
    },
    mounted() {
        this.initSwiper()
    }
}

</script>

<style lang="scss" scoped>

@import '@/assets/scss/mixins.scss';
@import '@/assets/scss/functions.scss';

.swiper {
    width: 100%;
    margin-bottom: 30px;
    height: 376px;
    display: none;

    @include respond-to(wide-and-up) {
        display: block;
    }

    .swiper-wrapper {
        margin-bottom: 30px;
        max-width: 750px;

        @include respond-to(wide-and-up) {
            max-width: var(--peq-max-width);
        }
    }

    .swiper-slide {
        margin-right: 10px;
        width: auto!important;
    }

    .swiper-scrollbar {
        position: absolute;
        bottom: 0;
        width: 60%;
        height: 2px;
        background: var(--peq-light-grey);
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
    
        .swiper-scrollbar-drag{
            height: 100%;
            background: var(--peq-navy);
        }
    }

    .benefits-container {
        padding: 0;
        display: grid;
        grid-auto-flow: column dense;
        grid-template-rows: 170px 170px;
        grid-auto-columns: 169px;
        justify-content: center;

        .benefit-card:nth-child(4n + 2) {
            grid-row: 1; 
        }

        .benefit-card {
            margin: 0 15px 15px 0;
        }
    }
}

.mobile-benefits-container {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 450px;
    overflow: hidden;
    margin-top: 0;

    @include respond-to(wide-and-up) {
        display: none;
    }

    .benefit-card {
        margin: 0 15px 15px 0;
    }
}

</style>