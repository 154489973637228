export default {
    categories: [
        'All',
        'Asset Protection',
        'Health',
        'Lifestyle Planning',
        'Wealth',
    ],
    benefits: [
        {
            benefit: '529 Plans',
            icon: '529-plans-icon',
            description: 'A 529 college savings plan is an investment account that offers tax benefits when used to pay for qualified education expenses for a designated beneficiary.',
            categories: ['Wealth']
        },
        {
            benefit: 'Artificial Intelligence',
            icon: 'artificial-intelligence-icon',
            description: 'Artificial Intelligence - Machine learning algorithms and software, or artificial intelligence, that mimic human cognition in analysis, presentation and comprehension of complex medical and health care data.',
            categories: ['Health']
        },
        {
            benefit: 'Cancer',
            icon: 'cancer-icon',
            description: 'Support, resources, testing and/or treatment of cancer.',
            categories: ['Health']
        },
        {
            benefit: 'Caregiver Support',
            icon: 'elder-care-icon',
            description: 'Caregiver support can provide workshops and support groups to provide respite for individuals providing care for aging or disabled parents, children and friends.',
            categories: ['Health', 'Lifestyle Planning']
        },
        {
            benefit: 'Charitable Giving',
            icon: 'charitable-giving-icon',
            description: 'Amplifying philanthropic impact by simplifying ways to donate to charitable organizations through an employee\'s work.',
            categories: ['Lifestyle Planning', 'Wealth']
        },
        {
            benefit: 'Child Care',
            icon: 'family-health-icon',
            description: 'Facilitating accessible child care options for working parents.',
            categories: ['Lifestyle Planning']
        },
        {
            benefit: 'Chronic Disease Management',
            icon: 'chronic-disease-management-icon',
            description: 'An integrated care approach to managing illness which includes screenings, checkups, monitoring and coordinating treatment, and patient education.',
            categories: ['Health']
        },
        // {
        //     benefit: 'Commuter Benefits',
        //     icon: 'commuter-benefits-icon',
        //     description: 'Helping with costs related to transportation to and from work.',
        //     categories: ['Lifestyle Planning']
        // },
        {
            benefit: 'Cyber Security',
            icon: 'identity-theft-icon',
            description: 'Cyber security is the technologies and processes to help protect the confidentiality, integrity and availability of computer systems, networks and data, against cyber-attacks or unauthorized access.',
            categories: ['Asset Protection']
        },
        {
            benefit: 'Dental Care',
            icon: 'dental-icon',
            description: 'Access to dental care for overall oral health, including preventive care and services (cleanings, fluoride, x-rays, etc.).',
            categories: ['Health']
        },
        {
            benefit: 'Diabetes',
            icon: 'diabetes-icon',
            description: 'On-demand medical care, individualized treatment plan, education and accountability support to help patients with diabetes.',
            categories: ['Health']
        },
        {
            benefit: 'Emergency Ground & Air Ambulance Coverage',
            icon: 'emergency-icon',
            description: 'A benefit that covers the out-of-pocket expenses associated with emergency ground or air ambulance transportation services.',
            categories: ['Health']
        },
        {
            benefit: 'Employee Assistance Programs (EAP)',
            icon: 'employee-assistance-programs-icon',
            description: 'Voluntary benefit programs that provide personal or work related assistance to employees.',
            categories: ['Health', 'Lifestyle Planning']
        },
        {
            benefit: 'Employee Rewards Programs',
            icon: 'employee-rewards-programs-icon',
            description: 'Employee Rewards programs can be an effective way to incentive employees. Typically, they include easy to use software to give gifts or awards to employees. Rewards programs can be used to recognize accomplishments, build team strength or to show employee appreciation.',
            categories: ['Lifestyle Planning']
        },
        {
            benefit: 'Family Health',
            icon: 'family-health-icon',
            description: 'Family health is the health of a family as a whole, including parents and children.',
            categories: ['Health']
        },
        {
            benefit: 'Fertility Treatment',
            icon: 'fertility-icon',
            description: 'Fertility benefits can include a wide range of treatments and services that are covered by your employer. Some of the services include infertility diagnosis, in-vitro fertilization (IVF), egg freezing, donor sperm or eggs, intrauterine insemination (IUI), and gestational carrier services, among others.',
            categories: ['Health']
        },
        {
            benefit: 'Financial Education',
            icon: 'financial-education-icon',
            description: 'Financial education is critical to improving the economic self-sufficiency of all workers and empowers employees to make smart financial decisions.',
            categories: ['Wealth']
        },
        {
            benefit: 'Financial Wellness',
            icon: 'financial-wellness-icon',
            description: 'Tools and resources that can help employees make and stick to a budget, get out of debt, save for retirement and future with confidence.',
            categories: ['Wealth']
        },
        {
            benefit: 'Food and Nutrition',
            icon: 'food-and-nutrition-icon',
            description: 'Support and resources for optimal food and nutrition.',
            categories: ['Health']
        },
        // {
        //     benefit: 'Genetic Testing',
        //     icon: 'genetic-testing-icon',
        //     description: 'Genetic testing looks for mutations or changes in your DNA. These findings can help predict illness or disease and can assist with prevention.',
        //     categories: ['Health']
        // },
        {
            benefit: 'Home/Auto',
            icon: 'home-auto-renters-icon',
            description: 'Homeowners insurance typically covers your dwelling, other structures on your property, personal property, personal liability, medical payments to others, and loss of use costs. Auto insurance protects you against financial loss in the event of an accident or theft.',
            categories: ['Asset Protection']
        },
        {
            benefit: 'Identity Theft',
            icon: 'identity-theft-icon',
            description: 'Helps employees resolve any identity theft-related problems.',
            categories: ['Wealth']
        },
        {
            benefit: 'Legal',
            icon: 'legal-icon',
            description: 'Legal plans are benefits designed to provide employees with a set of legal services and some level of protection against personal matters, ranging from document creation and online resources to legal consultation and representation.',
            categories: ['Wealth']
        },
        {
            benefit: 'Mental/Behavioral Health',
            icon: 'mental-behavioral-health-icon',
            description: 'Mental and behavioral health encompass our mental wellbeing as a whole, including social, emotional and psychological wellbeing.',
            categories: ['Health']
        },
        {
            benefit: 'Musculoskeletal',
            icon: 'musculoskeletal-management-icon',
            description: 'Musculoskeletal health is the health of muscles, bones, tendons, ligaments, joints and cartilage.',
            categories: ['Health']
        },
        {
            benefit: 'Navigation/Advocacy',
            icon: 'navigation-advocacy-icon',
            description: 'Navigation and advocacy focuses on getting the most out of your healthcare benefits by improving communication and the complexities of benefits.',
            categories: ['Health']
        },
        {
            benefit: 'Obesity Management',
            icon: 'obesity-icon',
            description: 'The treatment of obesity through weight loss, counseling and nutrition.',
            categories: ['Health']
        },
        {
            benefit: 'Pet Insurance',
            icon: 'pet-insurance-icon',
            description: 'Pet insurance is a health care policy for your pet that reimburses you for certain medical expenses.',
            categories: ['Wealth']
        },
        {
            benefit: 'Population Health',
            icon: 'population-health-icon',
            description: 'The benefits of Population Health management include better health outcomes with reduced care gaps and real-time monitoring.',
            categories: ['Health']
        },
        {
            benefit: 'Renters Insurance',
            icon: 'home-auto-renters-icon',
            description: 'Renters insurance helps cover unexpected events. This insurance can help you repair or replace property after loss due to many types of damage or theft.',
            categories: ['Asset Protection']
        },
        {
            benefit: 'Second Opinion',
            icon: 'second-opinion-icon',
            description: 'Providers that offer second opinion services facilitate the examination and evaluation of a health condition by a second physician to verify or challenge the diagnosis by a first physician.',
            categories: ['Health']
        },
        {
            benefit: 'Short Term Loans',
            icon: 'short-term-loans-icon',
            description: 'Short term loans are loans with little to no collateral that are to be repaid in a year or less, sometimes weeks or months.',
            categories: ['Wealth']
        },
        {
            benefit: 'Sleep',
            icon: 'sleep-icon',
            description: 'The treatment of sleep issues.',
            categories: ['Health']
        },
        {
            benefit: 'Social Determinants of Health',
            icon: 'social-determinants-of-health-icon',
            description: 'Social determinants of health (SDOH) are the nonmedical factors that influence health outcomes. They are the conditions in which people are born, grow, work, live, and age, and the wider set of forces and systems shaping the conditions of daily life.',
            categories: ['Health']
        },
        {
            benefit: 'Student Loan',
            icon: 'student-loan-icon',
            description: 'Managing student load debt.',
            categories: ['Lifestyle Planning', 'Wealth']
        },
        {
            benefit: 'Telemedicine/Virtual Primary Care',
            icon: 'telemedicine-virtual-primary-care-icon',
            description: 'Virtual primary care is a form of primary care that leverages technology to give patients convenient face time with their primary care provider. Telemedicine enables video or phone appointments between a patient and their health care practioner. Both  options provide health care and convenience.',
            categories: ['Health']
        },
        {
            benefit: 'Time Off Perks',
            icon: 'time-off-perks-icon',
            description: 'Employees can exchange their unused vacation time to give people flexible benefits that fit their lifestyles. From putting cash value from their vacation time towards retirement funds or student loans to donating extra sick days to coworkers or using it for travel.',
            categories: ['Lifestyle Planning']
        },
        {
            benefit: 'TPA',
            icon: 'tpa-icon',
            description: 'A third party administrator (TPA) provides administrative services for self-funded health plans.',
            categories: ['Lifestyle Planning']
        },
        {
            benefit: 'Transparency/Quality Cost',
            icon: 'transparency-quality-cost-icon',
            description: 'Valuing openness about healthcare costs and prices.',
            categories: ['Health']
        },
        {
            benefit: 'Whole Health',
            icon: 'whole-health-icon',
            description: 'Benefits that help an individual with all aspects of their wellbeing.',
            categories: ['Health']
        },
        {
            benefit: 'Women\'s Health',
            icon: 'womens-health-icon',
            description: 'Care focused on providing support for women\'s physical and emotional wellbeing.',
            categories: ['Health']
        }
        // {
        //     benefit: 'Work From Home',
        //     icon: 'work-from-home-icon',
        //     description: 'Affordable, subscription-based protection for when appliances and systems break down in your home.',
        //     categories: ['Lifestyle Planning']
        // }
    ]
}